import {ReactElement, useEffect, useState} from "react";
import {IStore} from "@/redux/defaultStore";
import {useDispatch, useSelector} from "react-redux";
import Toast from "@/components/Toast";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {BsExclamationCircleFill} from "react-icons/bs";
import FrameButton from "@/components/buttons/FrameButton";
import {useSpring, animated} from "@react-spring/web";
import {GamesApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {addError, toggleLoginOpen, updateAnonymousSpin} from "@/redux/meta/metaActions";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {isMobile} from "react-device-detect";
import classNames from "classnames";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import SignUpToast from "../SignUpToast";

enum SpinToWinState {
    WAITING,
    SPINNING,
    FINISHED,
}

interface Props {
    toggle: () => void;
    anonymousPrompt?: boolean;
    setSpinLocked: (spinning: boolean) => void;
}

function SpinToWin(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken);
    const {refetch: refetchUserLevel} = useGetUserLevel(fullToken, currentUser?.user.id);
    const dispatch = useDispatch();
    const history = useNavigate();
    const [
        showLoginToast,
        setShowLoginToast,
    ] = useState<boolean>(false);
    const [
        spinState,
        setSpinState,
    ] = useState<SpinToWinState>(SpinToWinState.WAITING);
    const [
        spinResult,
        setSpinResult,
    ] = useState<number>(0);
    const [
        pointsEarned,
        setPointsEarned,
    ] = useState<number>(0);
    const [
        endingAnimationVisible,
        setEndingAnimationVisible,
    ] = useState(false);
    const FULL_CIRCLE_DEG: number = 360;

    const [
        springs,
        api,
    ] = useSpring(() => ({
        from: {
            rotateZ: 0,
        },
        config: {
            friction: 10,
            mass: 1,
            tension: 10,
        },
        onRest: onSpinFinished,
    }));


    function handleLoginToastDismissal() {
        setShowLoginToast(false);
    }

    async function onSpinButtonClick(): Promise<void> {
        if (spinState === SpinToWinState.WAITING) {
            // should spin the wheel.
            setSpinState(SpinToWinState.SPINNING);
            props.setSpinLocked(true);

            try {
                const {result, rotationAmount, pointsEarned} = await new GamesApi(getConfig(fullToken)).spin2Win();
                if (fullToken) {
                    void refetchUserProfile();
                } else {
                    dispatch(updateAnonymousSpin({
                        spinDate: new Date(),
                        spinValue: pointsEarned,
                    }));
                }
                setSpinResult(result);
                setPointsEarned(pointsEarned);
                api.start({
                    from: {
                        rotateZ: 0,
                    },
                    to: {
                        // 360 deg * NUM_OF_SPINS - rotationAmount
                        rotateZ: FULL_CIRCLE_DEG * 10 - rotationAmount,
                    },
                });
            } catch (e) {
                dispatch(await addError(e));
            }
        }
    }

    function onSpinFinished(): void {
        setSpinState(SpinToWinState.FINISHED);
        props.setSpinLocked(false);
        setTimeout(() => setEndingAnimationVisible(true), 100);
    }

    function onConfirmSpin2Win(): void {
        if (currentUser) {
            void refetchUserLevel();
        } else {
            dispatch(toggleLoginOpen(true));
        }
        props.toggle();
        if (props.anonymousPrompt) {
            history("/go-vip-dashboard");
        }
    }

    function renderExplanationBlock(): ReactElement {
        switch (spinState) {
            case SpinToWinState.WAITING:
                return (
                    <h4 className="spin-to-win_explanation_text">
                        Click “SPIN” to start!
                    </h4>
                );
            case SpinToWinState.SPINNING:
                return (
                    <div className={isMobile
                        ? "spin-to-win_explanation_column"
                        : "spin-to-win_explanation_row"}>
                        <div className={classNames(
                            "spin-to-win_explanation_icon",
                            isMobile && "spin-to-win_explanation_icon_mobile",
                        )}>
                            <BsExclamationCircleFill/>
                        </div>
                        <h4 className={classNames(
                            "spin-to-win_explanation_text",
                            isMobile && "spin-to-win_explanation_text_mobile",
                        )}>
                            You cannot leave while the wheel is spinning.
                        </h4>
                    </div>
                );
            case SpinToWinState.FINISHED:
            default:
                return (
                    <div className={classNames("spin-to-win_explanation_congratulations", {
                        "anonymous-spin-2-win": props.anonymousPrompt,
                    })}>
                        <div
                            className={classNames("spin-to-win_explanation_congratulations_hidden", {
                                "spin-to-win_explanation_congratulations_visible": endingAnimationVisible,
                            })}
                        >
                            <div/>
                        </div>
                        <div
                            className={classNames("spin-to-win_explanation_congratulations_hidden", {
                                "spin-to-win_explanation_congratulations_visible": endingAnimationVisible,
                            })}
                            style={{
                                transitionDelay: "0.25s",
                            }}
                        >
                            <h4>
                                Congratulations!
                            </h4>
                        </div>
                        <div
                            className={classNames("spin-to-win_explanation_congratulations_hidden", {
                                "spin-to-win_explanation_congratulations_visible": endingAnimationVisible,
                            })}
                            style={{
                                transitionDelay: "0.5s",
                            }}
                        >
                            {pointsEarned > spinResult && !props.anonymousPrompt && <p>You earned {spinResult} XP and received a {parseFloat((pointsEarned / spinResult).toFixed(1))}x <br/> bonus, giving you a total of:</p>}
                            <h3>
                                {!props.anonymousPrompt ? <>{pointsEarned} XP</> : <>Sign up now to claim {pointsEarned} XP ✨</>}
                                <br />
                            </h3>
                        </div>
                        <div className={classNames("spin-to-win_explanation_congratulations_hidden", {
                            "spin-to-win_explanation_congratulations_visible": endingAnimationVisible,
                        })}
                        style={{
                            transitionDelay: "0.75s",
                        }}
                        >
                            <FrameButton
                                color="white-drop-shadow-devour"
                                size="narrow"
                                className="spin-to-win_explanation_congratulations_button"
                                onClick={onConfirmSpin2Win}
                            >
                                {!props.anonymousPrompt ? "Confirm" : "Claim My XP"}
                            </FrameButton>
                        </div>
                    </div>
                );
        }
    }

    return (
        <div
            className="spin-to-win"
        >
            <SignUpToast
                isOpen={showLoginToast}
                onDismiss={handleLoginToastDismissal}
            />
            <animated.div
                className="spin-to-win_wheel"
                style={springs}
            >
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/SpinWheel.webp`}
                    className="spin-to-win_untargetable"
                    alt="SpinToWin wheel"
                />
            </animated.div>
            <div className="spin-to-win_arrow">
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/SpinArrow.webp`}
                    className="spin-to-win_untargetable"
                    alt="SpinToWin Arrow"
                />
            </div>
            <div
                className={classNames(
                    "spin-to-win_button",
                    `spin-to-win_button_${spinState === SpinToWinState.WAITING
                        ? "enabled"
                        : "disabled"}`,
                )}
                onClick={onSpinButtonClick}
            >
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/Spin-btn.webp`}
                    className="spin-to-win_untargetable"
                    alt="SpinToWin button"
                />
            </div>
            <div className={classNames(
                "spin-to-win_explanation",
                isMobile && "spin-to-win_explanation_mobile",
            )}>
                {renderExplanationBlock()}
            </div>
        </div>
    );
}

export default SpinToWin;
