import classNames from "classnames";
import { useState } from "react";
import AnimateHeight from "react-animate-height";
import { BsTriangleFill } from "react-icons/bs";

interface props {
    question: string;
    answer: string | JSX.Element;
}
export const LandingPageQuestionCard = (props: props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="landing-page-question-card">
            <div className="landing-page-question-card_question" onClick={() => setIsExpanded(!isExpanded)}>
                <div className="landing-page-question-card_question_text">{props.question}</div>
                <div className="landing-page-question-card_question_icon">
                    <BsTriangleFill
                        className={classNames({
                            "flipped": !isExpanded,
                        })}
                    />
                </div>
            </div>
            <AnimateHeight
                duration={300}
                height={isExpanded ? "auto" : 0}
            >
                <div className="landing-page-question-card_answer">
                    <hr />
                    {props.answer}
                </div>
            </AnimateHeight>

        </div>
    );
};
