import {useQuery} from "@tanstack/react-query";
import {MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import { useEffect, useRef } from "react";
import {removeMenuOrderByOrderId} from "@/redux/meta/metaActions";
import {useDispatch} from "react-redux";

export const useMenuOrder = (menuOrderId: string, disableValidationWhenFetched = false) => {
    const dispatch = useDispatch();
    const isFetched = useRef(false);
    // Define the query function directly inside useQuery
    const result = useQuery({
        queryKey: [
            "menuOrder",
            menuOrderId,
        ],
        queryFn: async () => {
            if (!menuOrderId) {
                return null;
            }
            try {
                return await new MenuOrdersApi(getConfig()).getMenuOrder({
                    id: menuOrderId,
                });
            } catch (error: any) {
                dispatch(removeMenuOrderByOrderId(menuOrderId)); // Remove menuOrder from Redux
                throw error; // Re-throw error for React Query to handle
            }
        },
        // Options object
        enabled: Boolean(menuOrderId), // Conditional fetching based on menuOrderId presence
        retry: false,
    });

    useEffect(() => {
        if (result.isFetchedAfterMount) {
            isFetched.current = true;
        }
    }, [result.isFetchedAfterMount]);

    return {
        data: result.data,
        refetch: result.refetch,
        isFetching: result.isFetching,
        isFetchedAfterMount: result.isFetchedAfterMount,
        isLoading: result.isLoading,
    };
};
