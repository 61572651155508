import {Restaurant, RestaurantSearchResult} from "@devour/client";
import {ReactElement, useState} from "react";
import moment from "moment-timezone";
import {BsDot} from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import RestaurantDetailsModal from "@/pages/restaurants/components/RestaurantDetailsModal";

interface Props {
    restaurant: RestaurantSearchResult | Restaurant;
    hideMoreInfoIfClosed?: boolean;
    hideViewMoreInfo?: boolean;
}

function RestaurantHoursSummary(props: Props): ReactElement {
    const restaurantTimeZone = props.restaurant.timeZone;
    const restaurantMomentNow = moment().tz(restaurantTimeZone);
    const restaurantDayOfWeek = restaurantMomentNow.format("dddd").toUpperCase();
    const [
        showDetailsModal,
        setShowDetailsModal,
    ] = useState<boolean>(false);

    function getClosingTime(): JSX.Element {
        const serviceAvailabilityIndex = props.restaurant.serviceAvailabilities.findIndex((service) => {
            return service.dayOfWeek === restaurantDayOfWeek;
        });
        const serviceAvailability = props.restaurant.serviceAvailabilities[serviceAvailabilityIndex];
        const serviceAvailabilityNextDay = serviceAvailabilityIndex !== props.restaurant.serviceAvailabilities.length - 1
            ? props.restaurant.serviceAvailabilities[serviceAvailabilityIndex + 1]
            : props.restaurant.serviceAvailabilities[0];
        const nextDayMidnightOpen = serviceAvailabilityNextDay.timePeriods.find((period) => period.timeStart === "00:00");
        for (const time of serviceAvailability.timePeriods) {
            // Service availability is in the restaurant's local time
            const timeStart = moment.tz(time.timeStart, "HH:mm", restaurantTimeZone).date(restaurantMomentNow.date());
            const timeEnd = moment.tz(time.timeEnd, "HH:mm", restaurantTimeZone).date(restaurantMomentNow.date());

            // If "closing" at 23:59, check if the next day opens at 00:00 and show that time block instead
            const timeEndValue: string = time.timeEnd === "23:59" && nextDayMidnightOpen
                ? nextDayMidnightOpen.timeEnd
                : time.timeEnd;

            if (restaurantMomentNow.isBetween(timeStart, timeEnd)) {
                const timeEndString = moment.tz(timeEndValue, "HH:mm", restaurantTimeZone).date(restaurantMomentNow.date())
                    .format("LT");
                return (
                    <>
                        <span className="color-success">Open now</span>
                        <BsDot/>
						Closes at {timeEndString}
                    </>
                );
            }
        }
    }

    function toggleDetailsModal() {
        setShowDetailsModal(!showDetailsModal);
    }

    function getOpeningTime(): string {
        const serviceAvailability = props.restaurant.serviceAvailabilities.find((service) => {
            return service.dayOfWeek === restaurantDayOfWeek;
        });
        if (!serviceAvailability || !serviceAvailability?.timePeriods) {
            return "Closed Today";
        }
        for (const time of serviceAvailability.timePeriods) {
            // Service availability is in the restaurant's local time
            const timeStart = moment.tz(time.timeStart, "HH:mm", restaurantTimeZone).date(restaurantMomentNow.date());

            // Check if next opening is before the current time, and also still on the same day
            if (restaurantMomentNow.isBefore(timeStart) && timeStart.day() === restaurantMomentNow.day()) {
                return `Closed until ${timeStart.tz(restaurantTimeZone).format("LT")}`;
            }
        }
        if (props.restaurant.suspendUntil && props.restaurant.suspendUntil > Date.now()) {
            return "Temporarily Unavailable";
        }
        return "Closed for the day";
    }

    const seeDetailsButton: ReactElement =
		<span
		    className="business-hours-summary_details"
		    onClick={toggleDetailsModal}
		>
			View More Info
		    <FaChevronRight className="business-hours-summary_details_icon" />
		</span>;
    return (
        <>
            <RestaurantDetailsModal
                display={showDetailsModal}
                onToggle={toggleDetailsModal}
            />
            {props.restaurant.isOpen
                ? <div className="business-hours-summary is-open">
                    {getClosingTime()}
                    {!props.hideViewMoreInfo &&
                    <>
						    <BsDot/>
						    {seeDetailsButton}
                    </>
                    }
                </div>
			 : <div className="business-hours-summary is-closed">
                    {getOpeningTime()}
                    {!props.hideMoreInfoIfClosed && !props.hideViewMoreInfo &&
                    <>
						    <BsDot/>
						    {seeDetailsButton}
                    </>
                    }
                </div>
            }
        </>
    );
}

export default RestaurantHoursSummary;
