import classNames from "classnames";
import {useEffect, useState} from "react";
import AnimateHeight from "react-animate-height";
import { BsTriangleFill } from "react-icons/bs";
import {NftRewardPrize, LootboxReward} from "@devour/client";
import {useGetLootboxNftRewards} from "@/hooks/useGetLootboxNftRewards";

interface Props {
    level: number;
}

const defaultPrizes = [
    {
        id: "1",
        name: "Fantasy Dining Trip",
        quantity: 1,
    },
    {
        id: "2",
        name: "NAPA Wine Trip",
        quantity: 1,
    },
    {
        id: "3",
        name: "Eat Free 1-Year",
        quantity: 1,
    },
    {
        id: "4",
        name: "$1000 Fuel Tokens",
        quantity: 5,
    },
    {
        id: "5",
        name: "Oculus VR Kit",
        quantity: 15,
    },
    {
        id: "6",
        name: "High End Drone",
        quantity: 15,
    },
    {
        id: "7",
        name: "Merch Kits",
        quantity: 160,
    },
    {
        id: "8",
        name: "$500 Fuel Tokens",
        quantity: 200,
    },
    {
        id: "9",
        name: "$100 Fuel Tokens",
        quantity: 500,
    },
    {
        id: "10",
        name: "$50 Fuel Tokens",
        quantity: 600,
    },
    {
        id: "11",
        name: "$25 Fuel Tokens",
        quantity: 1500,
    },
    {
        id: "12",
        name: "$10 Fuel Tokens",
        quantity: 3000,
    },
    {
        id: "13",
        name: "$5 Fuel Tokens",
        quantity: 5500,
    },
];

export const GoVipLevelsLootBoxDetail = (props: Props) => {
    const [isLootBoxCollapsed, setIsLootBoxCollapsed] = useState(true);
    const [prizes, setPrizes] = useState<Array<NftRewardPrize>>(defaultPrizes as Array<NftRewardPrize>);
    const {data: lootboxRewards} = useGetLootboxNftRewards();

    useEffect(() => {
        if (lootboxRewards) {
            const reward = lootboxRewards.find((r: LootboxReward) => r.levelCompleted === props.level);
            if (reward) {
                setPrizes(reward.prizes);
            }
        }
    }, [lootboxRewards, props.level]);

    return (
        <div className={classNames("go-vip-levels-lootbox-detail", {
            expanded: !isLootBoxCollapsed,
        })}>
            <div className="go-vip-levels-lootbox-detail_heading">
                <h5>What’s Inside the Loot Box?</h5>
                <BsTriangleFill
                    className={classNames({
                        rotate: isLootBoxCollapsed,
                    })}
                    onClick={() => setIsLootBoxCollapsed(!isLootBoxCollapsed)}
                />
            </div>
            <AnimateHeight duration={300} height={isLootBoxCollapsed ? 0 : "auto"}>
                <div className="go-vip-levels-lootbox-detail_info">
                    Each prize in our collection is accompanied by a specified quantity limit. A prize will be randomly
                    selected from below.
                </div>

                <div className="go-vip-levels-lootbox-detail_prizes">
                    <div className="go-vip-levels-lootbox-detail_prizes_row row-header">
                        <span>Prize</span>
                        <span>Quantity</span>
                    </div>
                    <hr />
                    {prizes.map((prize) =>
                        <div className="go-vip-levels-lootbox-detail_prizes_row" key={prize.id}>
                            <span className="go-vip-levels-lootbox-detail_prizes_row_name">{prize.name}</span>
                            <span className="go-vip-levels-lootbox-detail_prizes_row_quantity">{prize.quantity}</span>
                        </div>)}
                </div>
            </AnimateHeight>
        </div>
    );
};
