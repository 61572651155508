import { Restaurant } from "@devour/client";
import { Dispatch, FC, SetStateAction } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { ViewStates } from "../RestaurantSearchResultsPage";
import { useGetRestaurants } from "@/hooks/useGetRestaurants";
import Skeleton from "react-loading-skeleton";
import RestaurantCard from "./RestaurantCard";
import { useParams } from "react-router";
import { converRestaurantToRestaurantSearchResult } from "@/utils/convertRestaurantToRestaurantSearchResult";

interface props {
    setViewState: Dispatch<SetStateAction<ViewStates>>;
    restaurantIds: string[];
}

export const PromoRestaurants: FC<props> = ({restaurantIds, setViewState}) => {
    const {placeId} = useParams<{ placeId: string; }>();

    const {data: restaurants, isFetching} = useGetRestaurants(restaurantIds);

    return (
        <div className="restaurant-view-all-promos">
            <div className="restaurant-view-all-promos_header">
                <FiChevronLeft onClick={() => setViewState(ViewStates.VIEW_ALL)}/>
                <h3>Promotion Restaurant's</h3>
                <span>{restaurantIds.length}</span>
            </div>
            <span className="restaurant-view-all-promos_subheader">Choose a location to redeem your promotion</span>

            <div className="restaurant-view-all-promos_list">
                {isFetching && Array.from({length: restaurantIds.length}, (_, i) => <div className="restaurants-search-results_list_item" key={i}>
                    <div className="restaurant_search_item_card ">
                        <Skeleton height={100}/>
                    </div>
                </div>)}
                {!isFetching && restaurants && restaurants.restaurants.map((restaurant: Restaurant) =>
                    <div className="restaurants-search-results_list_item">
                        <RestaurantCard
                            placeId={placeId}
                            restaurant={converRestaurantToRestaurantSearchResult(restaurant)}
                            showPromos="off"
                        />
                    </div>)}
            </div>
        </div>
    );
};
