import { isDev } from "@/utils/isDev";
import { useGate } from "statsig-react";


// statsig keys
export const ENABLE_LOOT_SHOP_FUEL_BACK_GATE_KEY = "enable_loot_shop_fuel_back";
export const ENABLE_LOOT_SHOP_ACCESS_GATE_KEY = "enable_loot_shop_access";


/**
 * Sets the gate key to the dev version if in development mode.
 * @param gate
 * @returns
 */
export const useScopedGate = (gate: string): { value: boolean } => {
    const key = isDev() ? `${gate}_dev` : gate;
    const {value} = useGate(key);
    return {
        value,
    };
};
