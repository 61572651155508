/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWReward,
    OWRewardFromJSON,
    OWRewardFromJSONTyped,
    OWRewardToJSON,
} from './';

/**
 * 
 * @export
 * @interface OWQuestResponse
 */
export interface OWQuestResponse extends OWReward {
}

export function OWQuestResponseFromJSON(json: any): OWQuestResponse {
    return OWQuestResponseFromJSONTyped(json, false);
}

export function OWQuestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWQuestResponse {
    return json;
}

export function OWQuestResponseToJSON(value?: OWQuestResponse | null): any {
    return value;
}


