import { Restaurant, RestaurantSearchResult } from "@devour/client";

export const converRestaurantToRestaurantSearchResult = (restaurant: Restaurant) => {
    return {
        _id: restaurant.id,
        ...restaurant,
        headerImage: restaurant.headerImage.url,
        icon: restaurant.icon.url,
        isOpen: restaurant.isOpen ?? false,
        phoneNumber: restaurant.phoneNumber,
    } as RestaurantSearchResult;
};
