/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OWEventType
 */
export interface OWEventType {
    /**
     * The name of the event type (e.g., \"KILL\", \"DEATH\")
     * @type {string}
     * @memberof OWEventType
     */
    name: string;
    /**
     * The structure/schema of the event data
     * @type {object}
     * @memberof OWEventType
     */
    dataStructure: object;
    /**
     * Whether the event data must match the dataStructure exactly or partially
     * @type {boolean}
     * @memberof OWEventType
     */
    partialMatch: boolean;
    /**
     * The ID of the game this event type is associated with
     * @type {string}
     * @memberof OWEventType
     */
    game?: string;
}

export function OWEventTypeFromJSON(json: any): OWEventType {
    return OWEventTypeFromJSONTyped(json, false);
}

export function OWEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWEventType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'dataStructure': json['dataStructure'],
        'partialMatch': json['partialMatch'],
        'game': !exists(json, 'game') ? undefined : json['game'],
    };
}

export function OWEventTypeToJSON(value?: OWEventType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dataStructure': value.dataStructure,
        'partialMatch': value.partialMatch,
        'game': value.game,
    };
}


