/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    OrderDiscountCalculation,
    OrderDiscountCalculationFromJSON,
    OrderDiscountCalculationFromJSONTyped,
    OrderDiscountCalculationToJSON,
    OrderDiscountFrequencyQualifier,
    OrderDiscountFrequencyQualifierFromJSON,
    OrderDiscountFrequencyQualifierFromJSONTyped,
    OrderDiscountFrequencyQualifierToJSON,
    OrderDiscountOperator,
    OrderDiscountOperatorFromJSON,
    OrderDiscountOperatorFromJSONTyped,
    OrderDiscountOperatorToJSON,
    OrderDiscountOrigin,
    OrderDiscountOriginFromJSON,
    OrderDiscountOriginFromJSONTyped,
    OrderDiscountOriginToJSON,
    OrderDiscountType,
    OrderDiscountTypeFromJSON,
    OrderDiscountTypeFromJSONTyped,
    OrderDiscountTypeToJSON,
    RelationshipType,
    RelationshipTypeFromJSON,
    RelationshipTypeFromJSONTyped,
    RelationshipTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateOrderDiscountBody
 */
export interface CreateOrderDiscountBody {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDiscountBody
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDiscountBody
     */
    internalName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDiscountBody
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrderDiscountBody
     */
    businesses?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDiscountBody
     */
    priority: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDiscountBody
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDiscountBody
     */
    amountMax: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderDiscountBody
     */
    isReusableToAmountMax?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrderDiscountBody
     */
    customers?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDiscountBody
     */
    subtotalMin: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDiscountBody
     */
    expiresAt?: number;
    /**
     * 
     * @type {OrderDiscountType}
     * @memberof CreateOrderDiscountBody
     */
    type: OrderDiscountType;
    /**
     * 
     * @type {OrderDiscountCalculation}
     * @memberof CreateOrderDiscountBody
     */
    calculation: OrderDiscountCalculation;
    /**
     * 
     * @type {OrderDiscountOrigin}
     * @memberof CreateOrderDiscountBody
     */
    origin: OrderDiscountOrigin;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderDiscountBody
     */
    isEnabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrderDiscountBody
     */
    nftGroupings?: Array<string>;
    /**
     * 
     * @type {OrderDiscountOperator}
     * @memberof CreateOrderDiscountBody
     */
    nftOperator?: OrderDiscountOperator;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrderDiscountBody
     */
    codes?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderDiscountBody
     */
    isSingleUseUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderDiscountBody
     */
    isSingleUseSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderDiscountBody
     */
    isStackablePromo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderDiscountBody
     */
    isFirstOrder: boolean;
    /**
     * 
     * @type {Array<RelationshipType>}
     * @memberof CreateOrderDiscountBody
     */
    referralTypes?: Array<RelationshipType>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderDiscountBody
     */
    isDisplayedGoFrens: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDiscountBody
     */
    maxRedemptions?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDiscountBody
     */
    useFrequency?: number;
    /**
     * 
     * @type {OrderDiscountFrequencyQualifier}
     * @memberof CreateOrderDiscountBody
     */
    useFrequencyQualifier?: OrderDiscountFrequencyQualifier;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrderDiscountBody
     */
    menuCategories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrderDiscountBody
     */
    menuItems?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDiscountBody
     */
    image?: string;
    /**
     * 
     * @type {Array<HandoffOptions>}
     * @memberof CreateOrderDiscountBody
     */
    handoffOptions?: Array<HandoffOptions>;
}

export function CreateOrderDiscountBodyFromJSON(json: any): CreateOrderDiscountBody {
    return CreateOrderDiscountBodyFromJSONTyped(json, false);
}

export function CreateOrderDiscountBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrderDiscountBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'internalName': !exists(json, 'internalName') ? undefined : json['internalName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'businesses': !exists(json, 'businesses') ? undefined : json['businesses'],
        'priority': json['priority'],
        'amount': json['amount'],
        'amountMax': json['amountMax'],
        'isReusableToAmountMax': !exists(json, 'isReusableToAmountMax') ? undefined : json['isReusableToAmountMax'],
        'customers': !exists(json, 'customers') ? undefined : json['customers'],
        'subtotalMin': json['subtotalMin'],
        'expiresAt': !exists(json, 'expiresAt') ? undefined : json['expiresAt'],
        'type': OrderDiscountTypeFromJSON(json['type']),
        'calculation': OrderDiscountCalculationFromJSON(json['calculation']),
        'origin': OrderDiscountOriginFromJSON(json['origin']),
        'isEnabled': json['isEnabled'],
        'nftGroupings': !exists(json, 'nftGroupings') ? undefined : json['nftGroupings'],
        'nftOperator': !exists(json, 'nftOperator') ? undefined : OrderDiscountOperatorFromJSON(json['nftOperator']),
        'codes': !exists(json, 'codes') ? undefined : json['codes'],
        'isSingleUseUser': json['isSingleUseUser'],
        'isSingleUseSystem': json['isSingleUseSystem'],
        'isStackablePromo': json['isStackablePromo'],
        'isFirstOrder': json['isFirstOrder'],
        'referralTypes': !exists(json, 'referralTypes') ? undefined : ((json['referralTypes'] as Array<any>).map(RelationshipTypeFromJSON)),
        'isDisplayedGoFrens': json['isDisplayedGoFrens'],
        'maxRedemptions': !exists(json, 'maxRedemptions') ? undefined : json['maxRedemptions'],
        'useFrequency': !exists(json, 'useFrequency') ? undefined : json['useFrequency'],
        'useFrequencyQualifier': !exists(json, 'useFrequencyQualifier') ? undefined : OrderDiscountFrequencyQualifierFromJSON(json['useFrequencyQualifier']),
        'menuCategories': !exists(json, 'menuCategories') ? undefined : json['menuCategories'],
        'menuItems': !exists(json, 'menuItems') ? undefined : json['menuItems'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'handoffOptions': !exists(json, 'handoffOptions') ? undefined : ((json['handoffOptions'] as Array<any>).map(HandoffOptionsFromJSON)),
    };
}

export function CreateOrderDiscountBodyToJSON(value?: CreateOrderDiscountBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'internalName': value.internalName,
        'description': value.description,
        'businesses': value.businesses,
        'priority': value.priority,
        'amount': value.amount,
        'amountMax': value.amountMax,
        'isReusableToAmountMax': value.isReusableToAmountMax,
        'customers': value.customers,
        'subtotalMin': value.subtotalMin,
        'expiresAt': value.expiresAt,
        'type': OrderDiscountTypeToJSON(value.type),
        'calculation': OrderDiscountCalculationToJSON(value.calculation),
        'origin': OrderDiscountOriginToJSON(value.origin),
        'isEnabled': value.isEnabled,
        'nftGroupings': value.nftGroupings,
        'nftOperator': OrderDiscountOperatorToJSON(value.nftOperator),
        'codes': value.codes,
        'isSingleUseUser': value.isSingleUseUser,
        'isSingleUseSystem': value.isSingleUseSystem,
        'isStackablePromo': value.isStackablePromo,
        'isFirstOrder': value.isFirstOrder,
        'referralTypes': value.referralTypes === undefined ? undefined : ((value.referralTypes as Array<any>).map(RelationshipTypeToJSON)),
        'isDisplayedGoFrens': value.isDisplayedGoFrens,
        'maxRedemptions': value.maxRedemptions,
        'useFrequency': value.useFrequency,
        'useFrequencyQualifier': OrderDiscountFrequencyQualifierToJSON(value.useFrequencyQualifier),
        'menuCategories': value.menuCategories,
        'menuItems': value.menuItems,
        'image': value.image,
        'handoffOptions': value.handoffOptions === undefined ? undefined : ((value.handoffOptions as Array<any>).map(HandoffOptionsToJSON)),
    };
}


