import useThemePreference from "@/hooks/useThemePreference";
import { ToastVariant, variantsConfig } from "@/types/ToastConfig";
import { FC, ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { IoClose } from "react-icons/io5";

interface AlertProps {
    variant: ToastVariant;
    message: string | ReactNode;
    onDismissClicked?: () => void;
    forceDarkMode?: boolean;
    showButton?: boolean;
    buttonMessage?: string;
    buttonFunction?: () => void | Promise<void>; // only fires when the button is clicked
    icon?: FC;
}

export const InlineAlert = (props: AlertProps) => {
    const { isOnDarkMode } = useThemePreference();
    const [isDarkMode, setIsDarkMode] = useState<boolean>(props.forceDarkMode ?? isOnDarkMode);

    const textColor = !isDarkMode
        ? "white"
        : "black";
    useEffect(() => {
        if (document.body.classList.contains("brand-light")) {
            setIsDarkMode(false);
        } else if (document.body.classList.contains("brand-dark")) {
            setIsDarkMode(true);
        }
    }, []);

    const Icon: FC = props.icon;

    return (
        <div className={classNames("inline-alert", `theme-${props.variant}`, {
            "dark": isDarkMode,
        })}
        style={{
            ...variantsConfig[props.variant]?.(isDarkMode, undefined, textColor)?.styles,
        }}
        >

            <div className="inline-alert_message">
                {Icon
                    ? <Icon />
                    : variantsConfig[props.variant]?.()?.icon
                        ? <img
                            src={variantsConfig[props.variant]().icon}
                            alt="toast-icon"
                        />
                        : null}
                {props.message}
            </div>
            {props.onDismissClicked && <div className="inline-alert_button">
                {props.showButton
                    ? <button
                        className="inline-alert_button_function"
                        onClick={async () => {
                            // call button function if there is one
                            if (props.buttonFunction) {
                                await props.buttonFunction();
                            }
                        }}
                    >
                        {props.buttonMessage
                            ? props.buttonMessage
                            : "Dismiss"}
                    </button>
                    : <IoClose
                        className="inline-alert_button_dismissal"
                        onClick={props.onDismissClicked}
                    />
                }
            </div>}

        </div>
    );
};
