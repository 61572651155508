import { DateTime } from "luxon";
import { QuestReset } from "@devour/client";

/**
 * Time units used in the reset countdown
 */
export enum ResetTimeUnit {
    DAY = "DAY",
    HOUR = "HOUR",
    MINUTE = "MINUTE",
}

/**
 * Return value of getTimeUntilQuestReset function
 */
export type nextResetTime = {
    unit: ResetTimeUnit;
    value: number;
    str: string;
};

/**
 * Calculates time remaining until the next quest reset based on the reset type
 * Returns a human-readable string showing the most relevant time unit
 * @param questReset - The quest reset type (e.g., "MONTHLY", "DAILY", "WEEKLY", "NONE")
 * @returns {nextResetTime} Time remaining (e.g., "12 days", "10 hours", "5 mins")
 */
export function getTimeUntilQuestReset(questReset: QuestReset): nextResetTime {
    // Current time in New York timezone
    const now = DateTime.now().setZone("America/New_York");

    let nextReset: DateTime;

    switch (questReset) {
        case QuestReset.MONTHLY:
            // Get next month's first day at midnight EST
            nextReset = now
                .plus({ months: 1 })
                .startOf("month")
                .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                });
            break;

        case QuestReset.WEEKLY:
            // Get next week's start of week (Monday) at midnight EST
            nextReset = now
                .plus({ weeks: 1 })
                .startOf("week")
                .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                });
            break;

        case QuestReset.DAILY:
            // Get next day at midnight EST
            nextReset = now
                .plus({ days: 1 })
                .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                });
            break;

        default:
            throw new Error(`Unsupported quest reset type: ${questReset}`);
    }

    // Calculate the difference
    const diff = nextReset.diff(now, ["days", "hours", "minutes"]).toObject();

    // Round down all values
    const days = Math.floor(diff.days ?? 0);
    const hours = Math.floor(diff.hours ?? 0);
    const minutes = Math.floor(diff.minutes ?? 0);

    // Return the most relevant time unit
    if (days > 0) {
        return {
            unit: ResetTimeUnit.DAY,
            value: days,
            str: `${days} ${days === 1 ? "day" : "days"}`,
        };
    }
    if (hours > 0) {
        return {
            unit: ResetTimeUnit.HOUR,
            value: hours,
            str: `${hours} ${hours === 1 ? "hr" : "hrs"}`,
        };
    }
    const finalMinutes = Math.max(minutes, 0);
    return {
        unit: ResetTimeUnit.MINUTE,
        value: finalMinutes,
        str: `${finalMinutes} ${finalMinutes === 1 ? "min" : "mins"}`,
    };
}