/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SiteConfigurationKey {
    RESTAURANTSEARCHHERO = 'RESTAURANT_SEARCH_HERO',
    GOFRENSHERO = 'GO_FRENS_HERO',
    GOVIPHERO = 'GO_VIP_HERO',
    DPAYHERO = 'DPAY_HERO',
    INDUSTRYPAGEHERO = 'INDUSTRY_PAGE_HERO',
    REWARDSDPAYMENUORDERS = 'REWARDS_DPAY_MENU_ORDERS',
    MENUORDERDELIVERYCHARGE = 'MENU_ORDER_DELIVERY_CHARGE',
    DEFAULTMARKETPLACEFEEDELIVERY = 'DEFAULT_MARKETPLACE_FEE_DELIVERY',
    DEFAULTMARKETPLACEFEEPICKUP = 'DEFAULT_MARKETPLACE_FEE_PICKUP',
    INDUSTRYPROMOBANNER = 'INDUSTRY_PROMO_BANNER',
    DEVOURIQ = 'DEVOURIQ',
    DPAYLOADSREWARD = 'DPAY_LOADS_REWARD',
    INDUSTRYPASSPURCHASEREWARD = 'INDUSTRY_PASS_PURCHASE_REWARD',
    REFERAFRIENDFIRSTORDERREWARD = 'REFER_A_FRIEND_FIRST_ORDER_REWARD',
    SIGNUPREWARD = 'SIGN_UP_REWARD',
    LEVELUPDPAYREWARD = 'LEVEL_UP_DPAY_REWARD',
    LEVELUPGOVIPPOINTREWARD = 'LEVEL_UP_GO_VIP_POINT_REWARD',
    OWANNOUNCEMENTMODAL = 'OW_ANNOUNCEMENT_MODAL'
}

export function SiteConfigurationKeyFromJSON(json: any): SiteConfigurationKey {
    return SiteConfigurationKeyFromJSONTyped(json, false);
}

export function SiteConfigurationKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteConfigurationKey {
    return json as SiteConfigurationKey;
}

export function SiteConfigurationKeyToJSON(value?: SiteConfigurationKey | null): any {
    return value as any;
}

