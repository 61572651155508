import {
    ButtonHTMLAttributes,
    ChangeEventHandler,
    FormEvent,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import {MenuOrdersApi, PhoneNumberBody, UsersApi} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import FrameOneAutoPanel from "../../modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "../../modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "../../modals/autoPanelComponents/FrameAutoPanelBody";
import {addError, updateCurrentUser} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import FrameOnePhoneNumberInput from "../../inputs/FrameOnePhoneNumberInput";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {ReactComponent as AddPhone} from "../../../svgs/add-phone.svg";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import { useStreamerMode } from "@/hooks/useStreamerMode";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";
import {
    formatOptionalPhoneNumberForApiSubmission,
} from "@/utils/formatOptionalPhoneNumberForApiSubmission";
import {sendMessageToOW} from "@/hooks/useOverwolfInterop";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumberBody;
    saveOrderInfo: boolean;
}

const defaultValues: FormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: {
        countryCode: "US",
        nationalNumber: "",
    },
    saveOrderInfo: false,
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function CheckoutDetailsContactInfoModal(props: Props): ReactElement {

    const { menuOrderId, isDigitalStore } = useContext(RestaurantContext);

    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const { hideInputText } = useStreamerMode();
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const user = userProfileData?.user;

    const [formValues, setFormValues] = useState<FormValues>(defaultValues);
    const isMissingContactInfo = !menuOrder?.phoneNumber || !menuOrder?.firstName || !menuOrder?.lastName;

    useEffect(() => {
        if (props.isOpen && menuOrder) {
            setFormValues({
                firstName: menuOrder.firstName || "",
                lastName: menuOrder.lastName || "",
                email: menuOrder.email || "",
                phoneNumber: {
                    countryCode: "US",
                    nationalNumber: menuOrder.phoneNumber?.nationalNumber || "",
                },
                saveOrderInfo: false,
            });
        }
    }, [props.isOpen]);

    function inputOnChange(key: keyof Pick<FormValues, "firstName" | "lastName" | "email">): ChangeEventHandler<HTMLInputElement> {
        return (e) => {
            setFormValues({
                ...formValues,
                [key]: e.target.value,
            });
        };
    }

    function phoneNumberOnChange(phoneNumber: PhoneNumberBody): void {
        setFormValues({
            ...formValues,
            phoneNumber,
        });
    }

    function onSaveOrderInfoCheck() {
        setFormValues({
            ...formValues,
            saveOrderInfo: !formValues.saveOrderInfo,
        });
    }

    function onSaveAccountUpdate() {
        return onFormSubmit(undefined, true);
    }

    async function onFormSubmit(e?: FormEvent<HTMLFormElement>, updateAccount?: boolean): Promise<void> {
        e?.preventDefault();
        try {
            if (updateAccount || formValues.saveOrderInfo) {
                await new UsersApi(getConfig(fullToken)).updateProfile({
                    updateProfileBody: {
                        phoneNumber: updateAccount ? formatOptionalPhoneNumberForApiSubmission(formValues.phoneNumber) : user.phoneNumber,
                        firstName: updateAccount ? formValues.firstName : user.firstName,
                        lastName: updateAccount ? formValues.lastName : user.lastName,
                        ordersEmail: formValues.saveOrderInfo ? formValues.email : undefined,
                        ordersPhoneNumber: formValues.saveOrderInfo ? formatOptionalPhoneNumberForApiSubmission(formValues.phoneNumber) : undefined,
                        ordersFirstName: formValues.saveOrderInfo ? formValues.firstName : undefined,
                        ordersLastName: formValues.saveOrderInfo ? formValues.lastName : undefined,
                        silentDupeChecks: true,
                    },
                });

                const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
                dispatch(updateCurrentUser(userRes));
                // Tell Overwolf to update the user data
                sendMessageToOW({ type: "de:update-user",
                    payload: { userData: userRes } });
            }

            // update menu order
            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    ...formValues,
                },
            });
            await refetchMenuOrder();
            props.onClose();
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    return (
        <FrameOneAutoPanel
            toggle={props.onClose}
            isOpen={props.isOpen}
            size="sm"
            contentClassName="menu-order-contact-info-modal"
            disableOverlayDismiss={isMissingContactInfo}
            modalOnTablet={true}
        >
            <FrameAutoPanelHeader
                title=""
            >
                <div className="menu-order-contact-info-modal_header">
                    <AddPhone/>
                    <span className="menu-order-contact-info-modal_header_title">
                        Add Order Contact Information
                    </span>
                </div>
            </FrameAutoPanelHeader>

            <FrameAutoPanelBody className="menu-order-contact-info-modal_body">
                <form onSubmit={onFormSubmit}>
                    <p>
                        Please provide the details of the person who will receive the order.
                        This step is required to proceed with your order.
                    </p>

                    <div className="menu-order-contact-info-modal_body_divider"/>

                    <div className="menu-order-contact-info-modal_body_name-container">
                        <div className="frame-one-phone-input_input">
                            <label>
                                First Name
                            </label>

                            <input
                                type="text"
                                className="menu-order-contact-info-modal_body_name-input"
                                placeholder="First Name"
                                value={formValues.firstName}
                                onChange={inputOnChange("firstName")}
                                required={true}
                            />
                        </div>

                        <div className="frame-one-phone-input_input">
                            <label>Last Name</label>
                            <input
                                {...hideInputText()}
                                className="menu-order-contact-info-modal_body_name-input"
                                placeholder="Last Name"
                                value={formValues.lastName}
                                onChange={inputOnChange("lastName")}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="menu-order-contact-info-modal_body_email-container">
                        <label>Email</label>
                        <input
                            {...hideInputText()}
                            className="menu-order-contact-info-modal_body_name-input"
                            placeholder="Email"
                            value={formValues.email}
                            onChange={isDigitalStore ? undefined : inputOnChange("email")}
                            disabled={isDigitalStore}
                            required={true}
                        />
                    </div>

                    <div className="menu-order-contact-info-modal_body_phone-container">
                        <FrameOnePhoneNumberInput
                            value={formValues.phoneNumber}
                            onChange={phoneNumberOnChange}
                        />
                    </div>

                    <div className="menu-order-contact-info-modal_body_save-container">
                        <FrameOneCheckbox
                            onToggle={onSaveOrderInfoCheck}
                            checked={formValues.saveOrderInfo}
                            background={"purple"}
                        >
                            Save my info for future orders
                        </FrameOneCheckbox>
                    </div>

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="normal"
                        className="menu-order-contact-info-modal_body_submit-button"
                        showSpinner={true}
                        forwardProps={{type: "submit"}}
                    >
                        Save
                    </FrameButton>

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple-text-no-border"
                        size="normal"
                        className="menu-order-contact-info-modal_body_submit-button"
                        showSpinner={true}
                        forwardProps={{type: "button"}}
                        onClick={onSaveAccountUpdate}
                    >
                        Save and Update My Account Info
                    </FrameButton>
                </form>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default CheckoutDetailsContactInfoModal;
