/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    CreateOrderDiscountBody,
    CreateOrderDiscountBodyFromJSON,
    CreateOrderDiscountBodyToJSON,
    GetBusinessOwnEnabledStatus,
    GetBusinessOwnEnabledStatusFromJSON,
    GetBusinessOwnEnabledStatusToJSON,
    GetGlobalOrderDiscountsResponse,
    GetGlobalOrderDiscountsResponseFromJSON,
    GetGlobalOrderDiscountsResponseToJSON,
    GetOrderDiscountsResponse,
    GetOrderDiscountsResponseFromJSON,
    GetOrderDiscountsResponseToJSON,
    GetOrderDiscountsSort,
    GetOrderDiscountsSortFromJSON,
    GetOrderDiscountsSortToJSON,
    OrderDiscount,
    OrderDiscountFromJSON,
    OrderDiscountToJSON,
    OrderDiscountCalculation,
    OrderDiscountCalculationFromJSON,
    OrderDiscountCalculationToJSON,
    OrderDiscountOrigin,
    OrderDiscountOriginFromJSON,
    OrderDiscountOriginToJSON,
    OrderDiscountType,
    OrderDiscountTypeFromJSON,
    OrderDiscountTypeToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface CreateOrderDiscountRequest {
    createOrderDiscountBody?: CreateOrderDiscountBody;
}

export interface GetOrderDiscountsRequest {
    origin?: Array<OrderDiscountOrigin>;
    calculation?: Array<OrderDiscountCalculation>;
    type?: Array<OrderDiscountType>;
    search?: string;
    sort?: GetOrderDiscountsSort;
    isEnabled?: GetBusinessOwnEnabledStatus;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetOrderDiscountsSingleRequest {
    id: string;
}

export interface UpdateOrderDiscountRequest {
    id: string;
    createOrderDiscountBody?: CreateOrderDiscountBody;
}

/**
 * OrderDiscountsApi - interface
 * @export
 * @interface OrderDiscountsApiInterface
 */
export interface OrderDiscountsApiInterface {
    /**
     * Used to create a new order discount
     * @param {CreateOrderDiscountBody} [createOrderDiscountBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderDiscountsApiInterface
     */
    createOrderDiscountRaw(requestParameters: CreateOrderDiscountRequest): Promise<runtime.ApiResponse<OrderDiscount>>;

    /**
     * Used to create a new order discount
     */
    createOrderDiscount(requestParameters: CreateOrderDiscountRequest): Promise<OrderDiscount>;

    /**
     * Used to get a list the customer\'s qualified global discounts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderDiscountsApiInterface
     */
    getGlobalOrderDiscountsRaw(): Promise<runtime.ApiResponse<GetGlobalOrderDiscountsResponse>>;

    /**
     * Used to get a list the customer\'s qualified global discounts.
     */
    getGlobalOrderDiscounts(): Promise<GetGlobalOrderDiscountsResponse>;

    /**
     * Used to get a list of all order discounts
     * @param {Array<OrderDiscountOrigin>} [origin] 
     * @param {Array<OrderDiscountCalculation>} [calculation] 
     * @param {Array<OrderDiscountType>} [type] 
     * @param {string} [search] 
     * @param {GetOrderDiscountsSort} [sort] 
     * @param {GetBusinessOwnEnabledStatus} [isEnabled] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderDiscountsApiInterface
     */
    getOrderDiscountsRaw(requestParameters: GetOrderDiscountsRequest): Promise<runtime.ApiResponse<GetOrderDiscountsResponse>>;

    /**
     * Used to get a list of all order discounts
     */
    getOrderDiscounts(requestParameters: GetOrderDiscountsRequest): Promise<GetOrderDiscountsResponse>;

    /**
     * Used to get a single order Discount
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderDiscountsApiInterface
     */
    getOrderDiscountsSingleRaw(requestParameters: GetOrderDiscountsSingleRequest): Promise<runtime.ApiResponse<OrderDiscount>>;

    /**
     * Used to get a single order Discount
     */
    getOrderDiscountsSingle(requestParameters: GetOrderDiscountsSingleRequest): Promise<OrderDiscount>;

    /**
     * Used to update an existing order discount
     * @param {string} id 
     * @param {CreateOrderDiscountBody} [createOrderDiscountBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderDiscountsApiInterface
     */
    updateOrderDiscountRaw(requestParameters: UpdateOrderDiscountRequest): Promise<runtime.ApiResponse<OrderDiscount>>;

    /**
     * Used to update an existing order discount
     */
    updateOrderDiscount(requestParameters: UpdateOrderDiscountRequest): Promise<OrderDiscount>;

}

/**
 * no description
 */
export class OrderDiscountsApi extends runtime.BaseAPI implements OrderDiscountsApiInterface {

    /**
     * Used to create a new order discount
     */
    async createOrderDiscountRaw(requestParameters: CreateOrderDiscountRequest): Promise<runtime.ApiResponse<OrderDiscount>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-discounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderDiscountBodyToJSON(requestParameters.createOrderDiscountBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDiscountFromJSON(jsonValue));
    }

    /**
     * Used to create a new order discount
     */
    async createOrderDiscount(requestParameters: CreateOrderDiscountRequest): Promise<OrderDiscount> {
        const response = await this.createOrderDiscountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get a list the customer\'s qualified global discounts.
     */
    async getGlobalOrderDiscountsRaw(): Promise<runtime.ApiResponse<GetGlobalOrderDiscountsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-discounts/view/global`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGlobalOrderDiscountsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get a list the customer\'s qualified global discounts.
     */
    async getGlobalOrderDiscounts(): Promise<GetGlobalOrderDiscountsResponse> {
        const response = await this.getGlobalOrderDiscountsRaw();
        return await response.value();
    }

    /**
     * Used to get a list of all order discounts
     */
    async getOrderDiscountsRaw(requestParameters: GetOrderDiscountsRequest): Promise<runtime.ApiResponse<GetOrderDiscountsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.origin) {
            queryParameters['origin'] = requestParameters.origin;
        }

        if (requestParameters.calculation) {
            queryParameters['calculation'] = requestParameters.calculation;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.isEnabled !== undefined) {
            queryParameters['isEnabled'] = requestParameters.isEnabled;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-discounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderDiscountsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get a list of all order discounts
     */
    async getOrderDiscounts(requestParameters: GetOrderDiscountsRequest): Promise<GetOrderDiscountsResponse> {
        const response = await this.getOrderDiscountsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get a single order Discount
     */
    async getOrderDiscountsSingleRaw(requestParameters: GetOrderDiscountsSingleRequest): Promise<runtime.ApiResponse<OrderDiscount>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrderDiscountsSingle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-discounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDiscountFromJSON(jsonValue));
    }

    /**
     * Used to get a single order Discount
     */
    async getOrderDiscountsSingle(requestParameters: GetOrderDiscountsSingleRequest): Promise<OrderDiscount> {
        const response = await this.getOrderDiscountsSingleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to update an existing order discount
     */
    async updateOrderDiscountRaw(requestParameters: UpdateOrderDiscountRequest): Promise<runtime.ApiResponse<OrderDiscount>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrderDiscount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order-discounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderDiscountBodyToJSON(requestParameters.createOrderDiscountBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDiscountFromJSON(jsonValue));
    }

    /**
     * Used to update an existing order discount
     */
    async updateOrderDiscount(requestParameters: UpdateOrderDiscountRequest): Promise<OrderDiscount> {
        const response = await this.updateOrderDiscountRaw(requestParameters);
        return await response.value();
    }

}
