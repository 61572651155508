import React, {ReactElement, useEffect, useState} from "react";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "./autoPanelComponents/FrameAutoPanelFooter";
import FrameButton from "../buttons/FrameButton";
import {AssetsApi, GetUserResponse, UsersApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import EditImageModal from "./EditImageModal";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "@/redux/meta/metaActions";
import Toast from "../Toast";
import {useGetUserLeaderboardNickname} from "@/hooks/useGetUserLeaderboardNickname";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import EditProfile from "@/components/modals/editProfileModal/EditProfile";
import {DragEvents} from "@/components/modals/editProfileModal/dragEvents";
import EditProfilePicture from "@/components/modals/editProfileModal/EditProfilePicture";
import classNames from "classnames";
import {isDesktop} from "react-device-detect";
import {useParams} from "react-router";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onProfilePictureUpdate: () => void;
}

enum ImageContentTypes {
    GIF = "image/gif",
    JPG = "image/jpeg",
    PNG = "image/png",
}

function EditProfilePictureModal(props: Props): ReactElement {
    const {slug: brandMapSlug} = useParams<{ slug: string }>();
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser) as GetUserResponse;
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        inputName,
        setInputName,
    ] = useState<string>("");
    const [
        dragState,
        setDragState,
    ] = useState<DragEvents>(DragEvents.ExitDocument);
    const [
        image,
        setImage,
    ] = useState<string>("");
    const [
        editImageModal,
        setEditImageModal,
    ] = useState<boolean>(false);
    const [
        count,
        setCount,
    ] = useState<number>(0);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);
    const {data: userLeaderboardNickname} = useGetUserLeaderboardNickname(fullToken);
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken);
    const [
        showPfpOptions,
        setShowPfpOptions,
    ] = useState<boolean>(false);
    const {refetch: refetchLeaderboardNickname} = useGetUserLeaderboardNickname(fullToken);


    useEffect(() => {
        if (userLeaderboardNickname) {
            setInputName(userLeaderboardNickname.nickname);
        }
    }, [userLeaderboardNickname]);

    useEffect(() => {
        if (props.isOpen) {
            setShowPfpOptions(false);
        }
    }, [props.isOpen]);

    async function updateNickname(): Promise<void> {
        try {
            await new UsersApi(getConfig(fullToken)).updateLeaderboardNickname({
                updateNicknameBody: {
                    nickname: inputName,
                },
            });
            const userRes = await refetchUserProfile();

            if (userRes.data) {
                dispatch(updateCurrentUser(userRes.data));
            }
            await refetchLeaderboardNickname();
            setShowToast(true);
        } catch (e) {
            dispatch(await addError(e));
        }
    }
    function handleDragEvents(event: DragEvents): React.DragEventHandler<HTMLDivElement> {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (event === dragState) {
                return;
            }

            switch (event) {
                case DragEvents.Drop:
                    if (dragState === DragEvents.Hover) {
                        uploadPhoto(e);
                    }
                    setDragState(DragEvents.ExitDocument);
                    return;
                case DragEvents.Hover:
                    e.dataTransfer.dropEffect = "copy";
                    setDragState(event);
                    return;
                case DragEvents.Enter:
                case DragEvents.ExitDocument:
                case DragEvents.ExitHover:
                default:
                    setDragState(event);

            }
        };
    }

    function uploadPhoto(e): void {
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as string);
        };

        if (files && Object.values(ImageContentTypes).includes(files[0].type)) {
            reader.readAsDataURL(files[0]);
            setEditImageModal(true);

        } else {
            addError(new Error("The uploaded file is not a supported content type. Please try again.") as any).then(res => {
                dispatch(res);
            })
                .catch(e => {
                    dispatch(e);
                });
        }
    }

    async function editImageModalOnClose(): Promise<void> {
        const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
        dispatch(updateCurrentUser(userRes));
        setCount(count + 1);
        props.onProfilePictureUpdate();
        setShowPfpOptions(false);
        setEditImageModal(false);
    }

    async function removeAvatar(): Promise<void> {
        dispatch(incrementLoading());
        try {
            if (currentUser?.user.profilePicture) {
                await new AssetsApi(getConfig(fullToken)).deleteAsset({
                    id: currentUser?.user.profilePicture.id,
                });
            }
            await new UsersApi(getConfig(fullToken)).removeProfilePicture();

            const userRes = await refetchUserProfile();

            if (userRes.data) {
                dispatch(updateCurrentUser(userRes.data));
            }
            setCount(count + 1);
            props.onProfilePictureUpdate();
            setShowToast(true);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function handleToastDismissal() {
        setShowToast(false);
    }

    return (
        <>
            <Toast
                message="Your changes were saved!"
                isOpen={showToast}
                onDismiss={handleToastDismissal}
                removeMarginAdjustment={!!brandMapSlug}
            />
            <div
                onDragOver={dragState !== DragEvents.Hover
                    ? handleDragEvents(DragEvents.Enter)
                    : undefined}
                onDragEnd={handleDragEvents(DragEvents.ExitDocument)}
                onDragLeave={handleDragEvents(DragEvents.ExitDocument)}
                onDrop={handleDragEvents(DragEvents.Drop)}
            >
                <EditImageModal
                    image={image}
                    isOpen={editImageModal}
                    onClose={editImageModalOnClose}
                />
                <FrameOneModal
                    isOpen={props.isOpen}
                    toggle={props.onClose}
                    contentClassName="edit-profile-modal"
                >
                    <FrameModalBody
                        className="edit-profile-modal_body"
                    >
                        {!showPfpOptions
                            ? <EditProfile
                                onClose={props.onClose}
                                handleDragEvents={handleDragEvents}
                                count={count}
                                dragState={dragState}
                                inputName={inputName}
                                setInputName={setInputName}
                                removeAvatar={removeAvatar}
                                setShowPfpOptions={setShowPfpOptions}
                            />
						 : <EditProfilePicture
                                onClose={props.onClose}
                                handleDragEvents={handleDragEvents}
                                count={count}
                                dragState={dragState}
                                setInputName={setInputName}
                                removeAvatar={removeAvatar}
                                setShowPfpOptions={setShowPfpOptions}
                                uploadPhoto={uploadPhoto}
                            />
                        }
                        {!showPfpOptions && <FrameButton
						        color="purple"
						        size="normal"
						        className="edit-profile-modal_footer_button"
						        onClick={async () => {
						            await updateNickname();
						            props.onClose();
						        }}
						    >
								Save
						    </FrameButton>}
                    </FrameModalBody>
                </FrameOneModal>
            </div>
        </>
    );
}

export default EditProfilePictureModal;
