import {BusinessesApi, GetNftRewardResponse, GetRestaurantsResponse, NftsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";

async function fetchRestaurantsByIds(restaurantIds: string[]): Promise<GetRestaurantsResponse> {
    return await new BusinessesApi(getConfig()).getRestaurants({
        limit: 100,
        offset: 0,
        ids: restaurantIds,
    });
}

function getRestaurantsQuery(restaurantIds: string[]) {
    return {
        queryKey: [
            "restaurants-by-ids",
            restaurantIds,
        ],
        queryFn: () => fetchRestaurantsByIds(restaurantIds),
        keepPreviousData: true,
        staleTime: 30 * 60000,
        refetchOnWindowFocus: false,
        enabled: restaurantIds.length > 0,
    } as const;
}

export function useGetRestaurants(restaurantIds: string[]) {
    return useQuery(getRestaurantsQuery(restaurantIds));
}
