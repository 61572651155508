/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateProfileEmailBody
 */
export interface UpdateProfileEmailBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileEmailBody
     */
    email: string;
}

export function UpdateProfileEmailBodyFromJSON(json: any): UpdateProfileEmailBody {
    return UpdateProfileEmailBodyFromJSONTyped(json, false);
}

export function UpdateProfileEmailBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileEmailBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function UpdateProfileEmailBodyToJSON(value?: UpdateProfileEmailBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}


