import {ButtonHTMLAttributes, FormEvent, ReactElement, useEffect, useState} from "react";
import {Address, ErrorType, UtilsApi, AddressBooksApi} from "@devour/client";
import FrameButton from "../../../components/buttons/FrameButton";
import {addError, decrementLoading, incrementLoading, updateLastSearchedPlaceId} from "../../../redux/meta/metaActions";
import FrameOneAddressAutocomplete from "../../../components/inputs/FrameOneAddressAutocomplete";
import {useNavigate} from "react-router";
import {BiTargetLock} from "react-icons/bi";
import {useDispatch} from "react-redux";
import FrameOneAddressInputGroup from "../../../components/inputs/FrameOneAddressInputGroup";
import {isAndroidApp} from "../../../utils/isGoNative";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";

const defaultValues: Address = {
    line1: "",
    line2: "",
    locality: "",
    administrativeArea: "",
    postalCode: "",
    country: "US",
};

interface Props {
    simple?: boolean;
}

function RestaurantSearchPage({ simple}: Props): ReactElement {

    const history = useNavigate();
    const dispatch = useDispatch();
    const [
        formValues,
        setFormValues,
    ] = useState<Address>(defaultValues);
    const [
        autocompleteOperational,
        setAutocompleteOperational,
    ] = useState<boolean>(true);

    const { isOnOverwolf } = useOverwolfInterop();

    useEffect(() => {
        if (isAndroidApp()) {

            try {
                gonative_library_ready();

            } catch { /* empty */ }
        }

        void getAutocompleteStatus();
    }, []);

    function gonative_library_ready() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gonative.android.geoLocation.promptAndroidLocationServices();
    }

    async function getAutocompleteStatus() {
        dispatch(incrementLoading());
        try {
            const res = await new AddressBooksApi().autocompleteStatus();
            setAutocompleteOperational(res.autoCompleteOperational);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function onManualSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        dispatch(incrementLoading());
        try {
            const res = await new UtilsApi().getPlaceIdFromAddress({
                address: formValues,
            });
            dispatch(updateLastSearchedPlaceId(res.placeId));
            history(`/restaurants/search/${res.placeId}`);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    /**
     * Handle the address inputs onChange.
     *
     * @param address
     */
    function addressOnChange(address: Address): void {
        setFormValues(address);
    }

    async function onGeolocation(position: GeolocationPosition) {
        dispatch(incrementLoading());
        try {
            const res = await new UtilsApi().getAddressFromLatLng({
                latitude: position.coords.latitude.toString(),
                longitude: position.coords.longitude.toString(),
            });
            dispatch(updateLastSearchedPlaceId(res.placeId));
            history(`/restaurants/search/${res.placeId}`);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function geolocationRedirect() {
        dispatch(incrementLoading());

        try {
            /*
             * `navigator.geolocation.getCurrentPosition` isn't an actual promise.
             * Need to wrap with a real promise and await to show loading screen.
             */
            const position: GeolocationPosition = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            await onGeolocation(position);
            // navigator.geolocation.getCurrentPosition(onGeolocation);
        } catch (e) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: e.message,
            }));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function androidGeolocationSuccess() {
        const locationOptions = {
            enableHighAccuracy: true,
        };
        navigator.geolocation.getCurrentPosition(geolocationRedirect, null, locationOptions);
    }

    async function onCurrentPosition() {
        if (isAndroidApp()) {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                gonative.android.geoLocation.promptAndroidLocationServices();
                await androidGeolocationSuccess();

            } catch (err) {

            }

        } else {

            if (!navigator.geolocation) {

                dispatch(await addError({
                    type: ErrorType.APP,
                    message: "Geolocation is not supported by this browser.",
                }));
                return;
            }

            await geolocationRedirect();
        }
    }

    function autocompleteCallback(newAddress: Address) {
        dispatch(updateLastSearchedPlaceId(newAddress.placeId));
        history(`/restaurants/search/${newAddress.placeId}`);
    }

    if (simple) {
        return (
            <div className="restaurant-initial-address-search">
                <div className="restaurant-initial-address-search_address">

                    <FrameOneAddressAutocomplete
                        placeholder="Enter food delivery address"
                        callback={autocompleteCallback}
                        onError={() => setAutocompleteOperational(false)}
                        showButton={false}
                    />

                    {!isOnOverwolf && <div className="restaurant-initial-address-search_geolocation">

                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="ghost"
                            size="narrow"
                            onClick={onCurrentPosition}
                            showSpinner={true}
                            className="restaurant-initial-address-search_geolocation_location"
                        >
                            <BiTargetLock className="svg svg-icon"/>
						Use my current location
                        </FrameButton>

                    </div>}

                </div>

            </div>
        );
    }

    return (
        <div className="restaurant-initial-address-search">

            <div className="restaurant-initial-address-search_address">
                <h6 className="restaurant-initial-address-search_address_label">Find a location near to you</h6>
                {autocompleteOperational
                    ? <FrameOneAddressAutocomplete
                        callback={autocompleteCallback}
                        onError={() => setAutocompleteOperational(false)}
                        showButton={true}
                    />
				 : <form onSubmit={onManualSubmit}>
                        <div className="restaurant-initial-address-search_address_field-group">
                            <FrameOneAddressInputGroup
                                value={formValues}
                                onChange={addressOnChange}
                                showModeChangeButton={false}
                                defaultMode="manual"
                            />
                        </div>

                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="normal"
                            className="restaurant-initial-address-search_address_submit-button"
                            forwardProps={{
                                type: "submit",
                            }}
                        >
							Confirm Address
                        </FrameButton>
                    </form>
                }

                {!isOnOverwolf && <div className="restaurant-initial-address-search_geolocation">

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="ghost"
                        size="narrow"
                        onClick={onCurrentPosition}
                        showSpinner={true}
                        className="restaurant-initial-address-search_geolocation_location"
                    >
                        <BiTargetLock className="svg svg-icon"/>
						Or use my current location
                    </FrameButton>

                </div>}

            </div>

        </div>
    );
}

export default RestaurantSearchPage;
