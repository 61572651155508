/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    CreateReferralRewardThresholdBody,
    CreateReferralRewardThresholdBodyFromJSON,
    CreateReferralRewardThresholdBodyToJSON,
    GenerateAllReferralsResponse,
    GenerateAllReferralsResponseFromJSON,
    GenerateAllReferralsResponseToJSON,
    GetReferralCodesResponse,
    GetReferralCodesResponseFromJSON,
    GetReferralCodesResponseToJSON,
    GetReferralRewardThresholdsResponse,
    GetReferralRewardThresholdsResponseFromJSON,
    GetReferralRewardThresholdsResponseToJSON,
    GetReferralRewardThresholdsSort,
    GetReferralRewardThresholdsSortFromJSON,
    GetReferralRewardThresholdsSortToJSON,
    ReferralLinkText,
    ReferralLinkTextFromJSON,
    ReferralLinkTextToJSON,
    ReferralLinkTextBody,
    ReferralLinkTextBodyFromJSON,
    ReferralLinkTextBodyToJSON,
    ReferralRewardThreshold,
    ReferralRewardThresholdFromJSON,
    ReferralRewardThresholdToJSON,
    ReferredUsersResponse,
    ReferredUsersResponseFromJSON,
    ReferredUsersResponseToJSON,
    RelationshipType,
    RelationshipTypeFromJSON,
    RelationshipTypeToJSON,
    RemoveReferralRequestBody,
    RemoveReferralRequestBodyFromJSON,
    RemoveReferralRequestBodyToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface CreateReferralThresholdRequest {
    createReferralRewardThresholdBody?: CreateReferralRewardThresholdBody;
}

export interface GetOwnReferralCodeRequest {
    userId?: string;
}

export interface GetReferralLinkTextRequest {
    relationshipType?: RelationshipType;
    referralCode?: string;
}

export interface GetReferralRewardThresholdsRequest {
    disabled?: boolean;
    sort?: GetReferralRewardThresholdsSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetReferredUsersRequest {
    id: string;
}

export interface RemoveReferralRequest {
    removeReferralRequestBody?: RemoveReferralRequestBody;
}

export interface UpdateReferralLinkTextRequest {
    referralLinkTextBody?: ReferralLinkTextBody;
}

export interface UpdateReferralThresholdRequest {
    id: string;
    createReferralRewardThresholdBody?: CreateReferralRewardThresholdBody;
}

/**
 * ReferralsApi - interface
 * @export
 * @interface ReferralsApiInterface
 */
export interface ReferralsApiInterface {
    /**
     * Allows admins to create referral thresholds.
     * @param {CreateReferralRewardThresholdBody} [createReferralRewardThresholdBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    createReferralThresholdRaw(requestParameters: CreateReferralThresholdRequest): Promise<runtime.ApiResponse<ReferralRewardThreshold>>;

    /**
     * Allows admins to create referral thresholds.
     */
    createReferralThreshold(requestParameters: CreateReferralThresholdRequest): Promise<ReferralRewardThreshold>;

    /**
     * generate all referrals data for exporting CSV in the admin portal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    generateAllReferralsRaw(): Promise<runtime.ApiResponse<GenerateAllReferralsResponse>>;

    /**
     * generate all referrals data for exporting CSV in the admin portal
     */
    generateAllReferrals(): Promise<GenerateAllReferralsResponse>;

    /**
     * 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    getOwnReferralCodeRaw(requestParameters: GetOwnReferralCodeRequest): Promise<runtime.ApiResponse<GetReferralCodesResponse>>;

    /**
     */
    getOwnReferralCode(requestParameters: GetOwnReferralCodeRequest): Promise<GetReferralCodesResponse>;

    /**
     * 
     * @param {RelationshipType} [relationshipType] 
     * @param {string} [referralCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    getReferralLinkTextRaw(requestParameters: GetReferralLinkTextRequest): Promise<runtime.ApiResponse<ReferralLinkText>>;

    /**
     */
    getReferralLinkText(requestParameters: GetReferralLinkTextRequest): Promise<ReferralLinkText>;

    /**
     * 
     * @param {boolean} [disabled] 
     * @param {GetReferralRewardThresholdsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    getReferralRewardThresholdsRaw(requestParameters: GetReferralRewardThresholdsRequest): Promise<runtime.ApiResponse<GetReferralRewardThresholdsResponse>>;

    /**
     */
    getReferralRewardThresholds(requestParameters: GetReferralRewardThresholdsRequest): Promise<GetReferralRewardThresholdsResponse>;

    /**
     * Get a user\'s referred users
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    getReferredUsersRaw(requestParameters: GetReferredUsersRequest): Promise<runtime.ApiResponse<ReferredUsersResponse>>;

    /**
     * Get a user\'s referred users
     */
    getReferredUsers(requestParameters: GetReferredUsersRequest): Promise<ReferredUsersResponse>;

    /**
     * removes a referral for a given user
     * @param {RemoveReferralRequestBody} [removeReferralRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    removeReferralRaw(requestParameters: RemoveReferralRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * removes a referral for a given user
     */
    removeReferral(requestParameters: RemoveReferralRequest): Promise<void>;

    /**
     * 
     * @param {ReferralLinkTextBody} [referralLinkTextBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    updateReferralLinkTextRaw(requestParameters: UpdateReferralLinkTextRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    updateReferralLinkText(requestParameters: UpdateReferralLinkTextRequest): Promise<void>;

    /**
     * Allows admins to update referral thresholds.
     * @param {string} id 
     * @param {CreateReferralRewardThresholdBody} [createReferralRewardThresholdBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    updateReferralThresholdRaw(requestParameters: UpdateReferralThresholdRequest): Promise<runtime.ApiResponse<ReferralRewardThreshold>>;

    /**
     * Allows admins to update referral thresholds.
     */
    updateReferralThreshold(requestParameters: UpdateReferralThresholdRequest): Promise<ReferralRewardThreshold>;

}

/**
 * no description
 */
export class ReferralsApi extends runtime.BaseAPI implements ReferralsApiInterface {

    /**
     * Allows admins to create referral thresholds.
     */
    async createReferralThresholdRaw(requestParameters: CreateReferralThresholdRequest): Promise<runtime.ApiResponse<ReferralRewardThreshold>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referral-reward-threshold`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReferralRewardThresholdBodyToJSON(requestParameters.createReferralRewardThresholdBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralRewardThresholdFromJSON(jsonValue));
    }

    /**
     * Allows admins to create referral thresholds.
     */
    async createReferralThreshold(requestParameters: CreateReferralThresholdRequest): Promise<ReferralRewardThreshold> {
        const response = await this.createReferralThresholdRaw(requestParameters);
        return await response.value();
    }

    /**
     * generate all referrals data for exporting CSV in the admin portal
     */
    async generateAllReferralsRaw(): Promise<runtime.ApiResponse<GenerateAllReferralsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/generate-all-referrals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateAllReferralsResponseFromJSON(jsonValue));
    }

    /**
     * generate all referrals data for exporting CSV in the admin portal
     */
    async generateAllReferrals(): Promise<GenerateAllReferralsResponse> {
        const response = await this.generateAllReferralsRaw();
        return await response.value();
    }

    /**
     */
    async getOwnReferralCodeRaw(requestParameters: GetOwnReferralCodeRequest): Promise<runtime.ApiResponse<GetReferralCodesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReferralCodesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getOwnReferralCode(requestParameters: GetOwnReferralCodeRequest): Promise<GetReferralCodesResponse> {
        const response = await this.getOwnReferralCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getReferralLinkTextRaw(requestParameters: GetReferralLinkTextRequest): Promise<runtime.ApiResponse<ReferralLinkText>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relationshipType !== undefined) {
            queryParameters['relationshipType'] = requestParameters.relationshipType;
        }

        if (requestParameters.referralCode !== undefined) {
            queryParameters['referralCode'] = requestParameters.referralCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/referral-link-text`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralLinkTextFromJSON(jsonValue));
    }

    /**
     */
    async getReferralLinkText(requestParameters: GetReferralLinkTextRequest): Promise<ReferralLinkText> {
        const response = await this.getReferralLinkTextRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getReferralRewardThresholdsRaw(requestParameters: GetReferralRewardThresholdsRequest): Promise<runtime.ApiResponse<GetReferralRewardThresholdsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.disabled !== undefined) {
            queryParameters['disabled'] = requestParameters.disabled;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referral-reward-thresholds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReferralRewardThresholdsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getReferralRewardThresholds(requestParameters: GetReferralRewardThresholdsRequest): Promise<GetReferralRewardThresholdsResponse> {
        const response = await this.getReferralRewardThresholdsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a user\'s referred users
     */
    async getReferredUsersRaw(requestParameters: GetReferredUsersRequest): Promise<runtime.ApiResponse<ReferredUsersResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getReferredUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/get-referred-users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferredUsersResponseFromJSON(jsonValue));
    }

    /**
     * Get a user\'s referred users
     */
    async getReferredUsers(requestParameters: GetReferredUsersRequest): Promise<ReferredUsersResponse> {
        const response = await this.getReferredUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * removes a referral for a given user
     */
    async removeReferralRaw(requestParameters: RemoveReferralRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referrals/remove-referral`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveReferralRequestBodyToJSON(requestParameters.removeReferralRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * removes a referral for a given user
     */
    async removeReferral(requestParameters: RemoveReferralRequest): Promise<void> {
        await this.removeReferralRaw(requestParameters);
    }

    /**
     */
    async updateReferralLinkTextRaw(requestParameters: UpdateReferralLinkTextRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referral-link-text`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralLinkTextBodyToJSON(requestParameters.referralLinkTextBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateReferralLinkText(requestParameters: UpdateReferralLinkTextRequest): Promise<void> {
        await this.updateReferralLinkTextRaw(requestParameters);
    }

    /**
     * Allows admins to update referral thresholds.
     */
    async updateReferralThresholdRaw(requestParameters: UpdateReferralThresholdRequest): Promise<runtime.ApiResponse<ReferralRewardThreshold>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateReferralThreshold.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/referral-reward-threshold/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReferralRewardThresholdBodyToJSON(requestParameters.createReferralRewardThresholdBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralRewardThresholdFromJSON(jsonValue));
    }

    /**
     * Allows admins to update referral thresholds.
     */
    async updateReferralThreshold(requestParameters: UpdateReferralThresholdRequest): Promise<ReferralRewardThreshold> {
        const response = await this.updateReferralThresholdRaw(requestParameters);
        return await response.value();
    }

}
