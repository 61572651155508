import {GetNftRewardResponse, NftsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";

async function fetchNftReward(trackerId: string): Promise<GetNftRewardResponse> {
    return await new NftsApi(getConfig()).getNftReward({
        id: trackerId,
    });
}

function getNftRewardQuery(trackerId: string) {
    return {
        queryKey: [
            "nft-reward",
            trackerId,
        ],
        queryFn: () => fetchNftReward(trackerId),
        keepPreviousData: true,
        staleTime: 30 * 60000,
        refetchOnWindowFocus: false,
        enabled: !!trackerId,
    } as const;
}

export function useGetNftReward(trackerId: string) {
    return useQuery(getNftRewardQuery(trackerId));
}