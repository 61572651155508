import { FaArrowLeft } from "react-icons/fa";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import React, { useEffect } from "react";
import { DragEvents } from "@/components/modals/editProfileModal/dragEvents";
import NftProfilePictureBadge from "@/components/NftProfilePictureBadge";
import { useGetUserProfile } from "@/hooks/useGetUserProfile";
import { NftsApi, Token } from "@devour/client";
import getConfig from "@/utils/getConfig";
import { useLocation } from "react-router-dom";
import { useGetNftOwnershipsForUser } from "@/hooks/useGetNftOwnershipsForUser";
import { MdClose } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { EditProfileAvatarEdit } from "./EditProfileAvatarEdit";

interface Props {
    onClose: () => void;
    handleDragEvents: (event: DragEvents) => React.DragEventHandler<HTMLDivElement>;
    count: number;
    dragState: DragEvents;
    inputName: string;
    setInputName: (val: string) => void;
    removeAvatar: () => Promise<void>;
    setShowPfpOptions: (val: boolean) => void;
}

export default function EditProfile(props: Props) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const location = useLocation();

    const { data: userProfileData } = useGetUserProfile(fullToken);
    const { refetch: refetchNftOwnerships } = useGetNftOwnershipsForUser(fullToken as Token);

    async function refreshNftTokenUri() {
        try {
            const res = await new NftsApi(getConfig(fullToken)).refreshNftTokenUriRaw();

            // Only refresh if tokenUris got updated
            if (res.raw.status === 201) {
                await refetchNftOwnerships();
            }
        } catch {
            // fail silently
        }
    }

    useEffect(() => {
        // Excluding gofrens because it handles refresh already on its page
        if (fullToken && !location.pathname.includes("gofriends")) {
            void refreshNftTokenUri();
        }
    }, []);

    return (
        <>
            <div className="edit-profile-modal_header">
                <div className="edit-profile-modal_header_spacer"></div>
                <h5>Edit Profile</h5>
                <button className="edit-profile-modal_header_icon" onClick={props.onClose}>
                    <IoClose className="frame-one-bottom-panel_header_title-row_close-icon icon-close" />
                </button>
            </div>
            <div className="edit-profile-modal_body">
                <div
                    className={classNames(
                        "edit-profile-modal_body_profile",
                        isDesktop ? "edit-profile-modal_body_profile_desktop" : "edit-profile-modal_body_profile_mobile",
                    )}
                >
                    <EditProfileAvatarEdit
                        canRemove={!!(userProfileData?.user.profilePicture || userProfileData?.user.nftProfilePicture)}
                        count={props.count}
                        dragState={props.dragState}
                        isOnEditMode={false}
                        handleDragEvents={props.handleDragEvents}
                        onEdit={() => props.setShowPfpOptions(true)}
                        removeAvatar={props.removeAvatar}
                    />
                </div>
                <div className="edit-profile-modal_body_content">
                    <h5 className="edit-profile-modal_body_content_label">Name</h5>
                    <input
                        value={props.inputName}
                        placeholder="Your nickname..."
                        className="edit-profile-modal_body_content_input"
                        onChange={(e) => props.setInputName(e.target.value)}
                    />
                    <div className="edit-profile-modal_body_default">
                        <p>Feel free to use the name you’re known by! Your name is visible to everyone in Devour platforms.</p>
                    </div>
                </div>
            </div>
        </>
    );
}
