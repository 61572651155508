import { FaArrowLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { ChangeEvent, useRef } from "react";
import FrameButton from "@/components/buttons/FrameButton";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import { useGetNftOwnerships } from "@/hooks/useGetNftOwnerships";
import { Token, UsersApi } from "@devour/client";
import GoFrensNftCard from "@/components/goFrens/GoFrensNftCard";
import { omit } from "lodash";
import getConfig from "@/utils/getConfig";
import { addError, decrementLoading, incrementLoading, updateCurrentUser } from "@/redux/meta/metaActions";
import { useGetUserProfile } from "@/hooks/useGetUserProfile";
import { IoClose } from "react-icons/io5";
import { EditProfileAvatarEdit } from "./EditProfileAvatarEdit";
import { DragEvents } from "./dragEvents";
import { LuImagePlus } from "react-icons/lu";
import { RiImageAddFill } from "react-icons/ri";
import Tooltip from "@/components/Tooltip";
import { MdInfo } from "react-icons/md";

interface Props {
    onClose: () => void;
    handleDragEvents: (event: DragEvents) => React.DragEventHandler<HTMLDivElement>;
    count: number;
    dragState: DragEvents;
    setInputName: (val: string) => void;
    removeAvatar: () => Promise<void>;
    setShowPfpOptions: (val: boolean) => void;
    uploadPhoto: (e: ChangeEvent) => void;
}
export default function EditProfilePicture(props: Props) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { data: nftOwnershipsData } = useGetNftOwnerships(fullToken as Token);
    const { data: userProfileData } = useGetUserProfile(fullToken);
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    const { refetch: refetchUserProfile } = useGetUserProfile(fullToken);

    const nftOwnershipsWithCompleteUri = nftOwnershipsData?.nftOwnerships?.filter((ownership) => ownership?.tokenUri && ownership.tokenUri.length);

    async function handleAddNftAsPfp(nftID: string) {
        dispatch(incrementLoading());
        try {
            await new UsersApi(getConfig(fullToken)).updateProfilePicture({
                updateProfilePictureBody: {
                    nftID,
                },
            });
            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));
            await refetchUserProfile();
            props.setShowPfpOptions(false);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderEmptyState() {
        return (
            <div className="edit-profile-picture-modal_body_no-nfts">
                <div className="edit-profile-picture-modal_body_no-nfts_text">
                    <img src={`${import.meta.env.VITE_CDN_URL}/images/industrypass-cards.webp`} alt="industry-pass-image" />
                    <p>
                        It looks like you don’t own any NFTs, but don’t worry! You can purchase the Industry Pass that
                        also gives you benefits!
                    </p>
                    <FrameButton onClick={() => {}} size="narrow" color="purple-blue-gradient">
                        Discover Industry Pass
                    </FrameButton>
                </div>
            </div>
        );
    }
    return (
        <>
            <div className="edit-profile-modal_header change-picture-header">
                <button className="edit-profile-modal_header_icon" onClick={() => props.setShowPfpOptions(false)}>
                    <FaArrowLeft className="frame-one-bottom-panel_header_title-row_close-icon" />
                </button>
                <h5 className="left-spacing">Edit Profile</h5>
                <button className="edit-profile-modal_header_icon" onClick={props.onClose}>
                    <IoClose className="frame-one-bottom-panel_header_title-row_close-icon icon-close" />
                </button>
            </div>
            <div className="edit-profile-picture-modal_body">
                <div className="edit-profile-modal_body_profile">
                    <EditProfileAvatarEdit
                        canRemove={!!(userProfileData?.user.profilePicture || userProfileData?.user.nftProfilePicture)}
                        count={props.count}
                        dragState={props.dragState}
                        handleDragEvents={props.handleDragEvents}
                        isOnEditMode={true}
                        removeAvatar={props.removeAvatar}
                        onEdit={() => inputRef?.current?.click()}
                    />
                </div>
                <div className="edit-profile-picture-modal_body_upload">
                    <input
                        {...omit(props, "className", "isOpen", "onClose", "onProfilePictureUpdate")}
                        value={undefined}
                        ref={inputRef}
                        type="file"
                        className="edit-profile-modal_body_content_text_input"
                        onChange={(e) => props.uploadPhoto(e)}
                    />
                    <FrameButton
                        color="gray"
                        size="normal"
                        className="edit-profile-picture-modal_body_upload_btn"
                        rightIcon={RiImageAddFill}
                        onClick={() => inputRef?.current?.click()}
                    >
                        Upload from device
                    </FrameButton>
                </div>
                <div className="edit-profile-picture-modal_body_divider"><span>OR</span></div>
                <h5 className="edit-profile-picture-modal_body_nfts-title">
                    Choose an NFT you own{" "}
                    <Tooltip outsideIcon={MdInfo}>
                        <h5>Connect Your External Wallet</h5>
                        <p>
                            By connecting your external wallet to your DevourGO account, we can display your owned NFTs
                            where you can choose to make it as your profile picture!
                        </p>
                        <p>
                            Note: Not all NFT collections are supported through DevourGO. To learn more, visit{" "}
                            <a href="/gofriends">GoFriends</a>{" "}
                        </p>
                    </Tooltip>{" "}
                </h5>
                {nftOwnershipsData?.nftOwnerships?.length
                    ? <div className="edit-profile-picture-modal_body_nfts">
                        <div className="edit-profile-picture-modal_body_nfts_container">
                            {nftOwnershipsWithCompleteUri.map((nft) =>
                                <GoFrensNftCard
                                    key={nft.id}
                                    ownership={nft}
                                    className="pfp-option"
                                    onClick={() => handleAddNftAsPfp(nft.id)}
                                />)}
                        </div>
                    </div>
                    : renderEmptyState()
                }
            </div>
        </>
    );
}
