import useThemePreference from "@/hooks/useThemePreference";
import {store} from "@/redux";
import {toggleSidebar} from "@/redux/meta/metaActions";
import {ThemePreference} from "@/types/Theme";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {isDesktop, isTablet} from "react-device-detect";
import useWindowSize from "@/hooks/useWindowSize";

const DEVOUR_CDN = import.meta.env.VITE_CDN_URL;

export default function OverwolfGatherPage() {
    const dispatch = useDispatch();
    const {setTheme} = useThemePreference();
    const windowSize = useWindowSize()[0];
    const isOnMobile = !isDesktop && !isTablet || windowSize < 600;
    // Close the sidebar menu if open
    useEffect(() => {
        dispatch(toggleSidebar(false));
        const originalTheme = store.getState().metaStore.themePreference;
        setTheme(ThemePreference.DARK);

        return () => {
            if (originalTheme) {
                setTheme(originalTheme);
            }
        };
    }, []);

    const getHeaderBannerImageUrl = () => {
        let screenSize: string;

        if (isOnMobile) {
            screenSize = "SM";
        } else {
            screenSize = "LG";
        }
        return `${DEVOUR_CDN}/images/OW-Discover-Header-Bnr-${screenSize}.webp`;
    };

    // Render the Header
    const renderHeader = () =>
        <div
            className="overwolf-discover-page_header-bnr"
            style={{
                backgroundImage: `url(${getHeaderBannerImageUrl()})`,
            }}
        >
            {/* Header Title */}
            <span className="overwolf-discover-page_header-bnr_title">
                GUILDS
            </span>

            {/* Description */}
            <span className="overwolf-discover-page_header-bnr_description">
                An exciting new page is coming soon!
                Check back soon :)
            </span>
        </div>;

    return (
        <>
            <div
                className="overwolf-discover-page"
            >
                {/* HEADER SECTION */}
                {renderHeader()}
            </div>
        </>
    );
}
