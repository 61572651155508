import {ButtonHTMLAttributes, Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {useSelector} from "react-redux";
import {NftPurchaseUpsert, NftPurchaseRequestBody} from "@devour/client";
import {IStore} from "@/redux/defaultStore";
import {BsFillCreditCardFill} from "react-icons/bs";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {capitalize} from "lodash";
import classNames from "classnames";
import {FiArrowRight, FiChevronLeft} from "react-icons/fi";
import CardPaymentsInput from "@/components/CardPaymentInputs";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import Spacer from "@/components/Spacer";
import {IoIosCheckmarkCircle} from "react-icons/io";
import {formatPriceAddCommaPreserveDecimals} from "@/utils/currencyFormatters";
import {NumericFormat} from "react-number-format";
import {NumberFormatValues} from "react-number-format/types/types";
import {PaymentStates} from "@/components/modals/mintIndustryPassModal/PaymentStates";
import {roundNumber} from "@/utils/roundNumber";
import {isDesktop, isMobile, isTablet} from "react-device-detect";

interface Props {
    paymentState: PaymentStates;
    setPaymentState: Dispatch<SetStateAction<PaymentStates>>;
    nftPurchase: NftPurchaseUpsert;
    onSubmit: () => void;
    getClaimableNft: (values: NftPurchaseRequestBody) => Promise<void>;
}

function MintIndustryPassModalSplitPayment(props: Props): ReactElement {

    const isLoading = useSelector((store: IStore) => store.metaStore.loadingIncrement);

    const [
        dpayAmount,
        setDpayAmount,
    ] = useState<number>(0);
    const [
        creditAmount,
        setCreditAmount,
    ] = useState<number>(0);

    // Stripe has a minimum $0.50 requirement. Do not allow credit to go below this amount
    const minFiatAmount = 0.5;
    const dpayBalanceInFiat = props.nftPurchase?.dpayBalance * props.nftPurchase?.dPayPricePerUsd;
    const maxDpayInFiat = Math.min(props.nftPurchase?.total - minFiatAmount, dpayBalanceInFiat - minFiatAmount);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: stripePaymentMethodListData} = useGetStripePaymentMethodList(fullToken);

    const savedPaymentMethods = (stripePaymentMethodListData?.paymentMethods) as Array<StripePaymentMethodObject>;
    const selectedPayment = savedPaymentMethods?.find((payment) => payment.id === props.nftPurchase.paymentMethodId);
    const selectedCreditCardNumber = `${capitalize(selectedPayment?.card.brand)} ****${selectedPayment?.card.last4}`;

    useEffect(() => {
        // Refresh form values on backend numbers update
        if (props.nftPurchase) {
            setDpayAmount(props.nftPurchase.fiatInDpay);
            setCreditAmount(props.nftPurchase.stripeTotal);
        }
    }, [props.nftPurchase]);

    /**
     * Handle dpay amount onChange events.
     *
     * @param values
     */
    function dpayOnChange(values: NumberFormatValues): void {
        setDpayAmount(values.floatValue);
    }

    function creditOnChange(values: NumberFormatValues): void {
        setCreditAmount(values.floatValue);
    }

    function calculateLowestCreditTotal(): number {
        const lowestCreditTotal = props.nftPurchase?.total - maxDpayInFiat;
        return Math.max(lowestCreditTotal, minFiatAmount);
    }

    function creditOffFocus(): void {
        const lowestCreditTotal = calculateLowestCreditTotal();
        // Use lowest credit if attempted amount is lower
        const newCreditAmount = creditAmount > lowestCreditTotal
            ? creditAmount
            : lowestCreditTotal;
        // Get the DPAY amount equal to the difference
        const dpayDifference = roundNumber(props.nftPurchase?.total - newCreditAmount);
        void props.getClaimableNft({fiatInDpay: dpayDifference});
    }

    function dpayOffFocus(): void {
        // Update quote with new amounts
        let dpayEntered = dpayAmount;

        if (dpayEntered > maxDpayInFiat) {
            dpayEntered = maxDpayInFiat;
        }

        void props.getClaimableNft({fiatInDpay: dpayEntered});
    }

    function renderSavedCards(payment: StripePaymentMethodObject): ReactElement {
        return (
            <div
                className={classNames("mint-industry-credit-card_body_credit_saved-card", {
                    "mint-industry-credit-card_body_credit_selected": props.nftPurchase.paymentMethodId === payment.id,
                })}
                key={payment.id}
                onClick={() => props.getClaimableNft({paymentMethodId: payment.id})}
            >
                <div>
                    <BsFillCreditCardFill className="mint-industry-credit-card_body_credit_saved-card_card-info_icon"/>
                </div>
                <div
                    className={classNames("mint-industry-credit-card_body_credit_saved-card_card-info", "sentry-mask")}>
                    <p><strong>{capitalize(payment.card.brand)} ****{payment.card.last4}</strong></p>
                </div>
                <Spacer/>
                <div>
                    {props.nftPurchase.paymentMethodId === payment.id &&
                    <IoIosCheckmarkCircle className="mint-industry-credit-card_body_credit_saved-card_selected"/>
                    }
                </div>
            </div>
        );
    }

    function renderCreditCardDetails(): ReactElement {
        return (
            <>
                <div className="mint-industry-credit-card_body_credit_section">
                    <CardPaymentsInput/>
                </div>

                {savedPaymentMethods?.length > 0 &&
                <div className="mint-industry-credit-card_body_credit_section">
                    <h5>Saved Cards</h5>
                    <div>
                        {savedPaymentMethods.map(renderSavedCards)}
                    </div>
                </div>
                }
            </>
        );
    }

    function onSelectedPaymentContinue(): void {
        void props.getClaimableNft({fiatInDpay: maxDpayInFiat});
        props.setPaymentState(PaymentStates.SPLIT_AMOUNT_STAGE);
    }

    return (
        <>
            <div
                className="mint-industry-credit-card_body_payment-options_header"
                onClick={() => props.setPaymentState(PaymentStates.SELECT_PAYMENT)}
            >
                <FiChevronLeft className="mint-industry-credit-card_body_payment-options_header_back"/>
                <h4>Pay with Credit Card and {import.meta.env.VITE_TOKEN_NAME}</h4>
            </div>
            {props.paymentState === PaymentStates.SPLIT_CREDIT_STAGE
                ? <>
                    {renderCreditCardDetails()}
                    <div className="mint-industry-credit-card_body_totals_button-container">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="large"
                            rightIcon={FiArrowRight}
                            showSpinner={true}
                            forwardProps={{disabled: !selectedPayment}}
                            onClick={onSelectedPaymentContinue}
                        >
                            Continue
                        </FrameButton>
                    </div>
                </>
                : <>
                    <p className="mint-industry-credit-card_body_split_description">
                        Default amount is set by maximum amount you can pay with FUEL. Any remaining amount will be
                        automatically charged and paid from the credit card specified.
                    </p>

                    <div className="mint-industry-credit-card_body_split_payments">
                        <div className="mint-industry-credit-card_body_split_payments_row">
                            <div className="mint-industry-credit-card_body_split_payments_row_left">
                                <BsFillCreditCardFill
                                    className="mint-industry-credit-card_body_payment-options_row_img"/>
                                <p><strong>{selectedCreditCardNumber}</strong></p>
                                {isMobile && !isTablet &&
                                <Spacer/>
                                }
                                <FrameButton
                                    <ButtonHTMLAttributes<HTMLButtonElement>>
                                    color="gray"
                                    size="pill"
                                    onClick={() => props.setPaymentState(PaymentStates.SPLIT_CREDIT_STAGE)}
                                >
                                    <strong>Edit</strong>
                                </FrameButton>
                            </div>
                            <div className="mint-industry-credit-card_body_split_payments_row_right">
                                <NumericFormat
                                    value={creditAmount}
                                    className="mint-industry-credit-card_body_split_payments_row_right_input"
                                    prefix="$"
                                    onValueChange={creditOnChange}
                                    onBlur={creditOffFocus}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    thousandSeparator=","
                                    disabled={isLoading > 0}
                                />
                                <p className="mint-industry-credit-card_body_split_payments_row_right_tag">
                                    <strong>USD</strong>
                                </p>
                            </div>

                            {isMobile && !isTablet &&
                            <p className="mint-industry-credit-card_body_dpay_min-amount">
                                    Min amount: ${minFiatAmount.toFixed(2)}
                            </p>
                            }

                        </div>
                        {(isDesktop || isTablet) &&
                        <p className="mint-industry-credit-card_body_dpay_min-amount">
                                Min amount: ${minFiatAmount.toFixed(2)}
                        </p>
                        }

                        <div className="mint-industry-credit-card_body_split_payments_row">
                            <div className="mint-industry-credit-card_body_split_payments_row_left">
                                <img
                                    className="mint-industry-credit-card_body_payment-options_row_img"
                                    src={import.meta.env.VITE_CDN_URL + "/images/FUEL.webp"}
                                    alt="DPAY Token Icon"
                                />
                                <p><strong>{isDesktop || isTablet
                                    ? `My ${import.meta.env.VITE_TOKEN_NAME} Balance`
                                    : `My ${import.meta.env.VITE_TOKEN_NAME}`}</strong></p>
                                {isMobile && !isTablet &&
                                <Spacer/>
                                }
                                <div className="mint-industry-credit-card_body_dpay_balance-card_usd_lighter-dark">
                                    <p>
                                        {
                                            `$${Intl.NumberFormat("en-us", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }).format(props.nftPurchase.dpayBalance * props.nftPurchase.dPayPricePerUsd)} USD`
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="mint-industry-credit-card_body_split_payments_row_right">
                                <NumericFormat
                                    onValueChange={dpayOnChange}
                                    onBlur={dpayOffFocus}
                                    value={dpayAmount}
                                    prefix="$"
                                    className="mint-industry-credit-card_body_split_payments_row_right_input"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    thousandSeparator=","
                                    disabled={isLoading > 0}
                                />
                                <p className="mint-industry-credit-card_body_split_payments_row_right_tag">
                                    <strong>USD</strong>
                                </p>
                            </div>

                            {isMobile && !isTablet &&
                            <p className="mint-industry-credit-card_body_dpay_remaining-balance">
                                    Remaining: {formatPriceAddCommaPreserveDecimals(props.nftPurchase.dpayBalance - props.nftPurchase.dpay, 0)} {import.meta.env.VITE_TOKEN_NAME}
                            </p>
                            }

                        </div>

                        {(isDesktop || isTablet) &&
                        <p className="mint-industry-credit-card_body_dpay_remaining-balance">
                                Remaining: {formatPriceAddCommaPreserveDecimals(props.nftPurchase.dpayBalance - props.nftPurchase.dpay, 0)} {import.meta.env.VITE_TOKEN_NAME}
                        </p>

                        }

                    </div>
                    <div className="mint-industry-credit-card_body_credit_footer">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            color="purple"
                            size="large"
                            className="mint-industry-credit-card_body_credit_footer_button"
                            rightIcon={FiArrowRight}
                            showSpinner={true}
                            onClick={props.onSubmit}
                        >
                            Purchase Now
                        </FrameButton>
                    </div>
                </>
            }
        </>
    );
}

export default MintIndustryPassModalSplitPayment;
