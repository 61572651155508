/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The time period at which we are setting the max reward for (weekly, monthly)
 * @export
 * @enum {string}
 */
export enum OWRewardMaxType {
    WEEKLY = 'WEEKLY'
}

export function OWRewardMaxTypeFromJSON(json: any): OWRewardMaxType {
    return OWRewardMaxTypeFromJSONTyped(json, false);
}

export function OWRewardMaxTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWRewardMaxType {
    return json as OWRewardMaxType;
}

export function OWRewardMaxTypeToJSON(value?: OWRewardMaxType | null): any {
    return value as any;
}

