import DragScroll from "@/components/DragScroll";
import Tooltip from "@/components/Tooltip";
import { isMobile, isTablet } from "react-device-detect";

const rewards = [
    {
        title: "Discounts",
        description: "Big savings on your favorite meals",
        image: "lp-earn-discounts.webp",
        tooltip: {
            title: "Food Discounts",
            description:
                "Get FREE promo codes for instant savings on your food orders from DevourGO! Unlock larger discounts as you level up higher!",
        },
    },
    {
        title: "FUEL Tokens",
        description: "Tokens to spend on food ordering",
        image: "lp-earn-fuel.webp",
        tooltip: {
            title: "FUEL Tokens",
            description:
                "Earn FUEL tokens as you level up! Use FUEL tokens for food orders and get 5% BACK on every placed orders with Fuel!",
        },
    },
    {
        title: "Loot Boxes",
        description: "Mysterious box with exclusive prizes",
        image: "lp-earn-lootboxes.webp",
        tooltip: {
            title: "Loot Boxes",
            description:
                "Score legendary drops packed with rewards worth $5 to $1000 in Fuel, premium gaming gear, and exclusive Devour merch!",
        },
    },
    {
        title: "Merchandise",
        description: "Devour’s useful merch items",
        image: "lp-earn-merch.webp",
        tooltip: {
            title: "Merchandise",
            description:
                "Get Devour merch items that may include sweatshirt, caps, bobblehead and more! It's your badge in our community!",
        },
    },
    {
        title: "Gift Cards",
        description: "Gaming gift cards for Steam and more",
        image: "lp-earn-gift-cards.webp",
        tooltip: {
            title: "Gift Cards",
            description: "Get Steam gift cards that you can use to purchase and play more Steam games!",
        },
    },
];

export const LandingPageEarnRewardsCards = () => {
    return (
        <div className="landing_eat-play-earn_earn-section_cards-container">
            {rewards.map((reward, index) =>
                <>
                    <div className="landing_eat-play-earn_earn-section_cards-container_card">
                        <img src={`${import.meta.env.VITE_CDN_URL}/images/${reward.image}`} alt="earn-discounts" />
                        <h6 className="normal-title">{reward.title}</h6>
                        <p className="normal-description">{reward.description}</p>
                        <h6 className="hover-title">{reward.tooltip.title}</h6>
                        <p className="hover-description">{reward.tooltip.description}</p>
                    </div>
                </>)}
        </div>

    );
};
