import classNames from "classnames";
import { DragEvents } from "./dragEvents";
import { useGetUserProfile } from "@/hooks/useGetUserProfile";
import { AiOutlineCloudUpload } from "react-icons/ai";
import NftProfilePictureBadge from "@/components/NftProfilePictureBadge";
import { IStore } from "@/redux/defaultStore";
import { useSelector } from "react-redux";
import { TbTrashXFilled } from "react-icons/tb";
import { MdEdit } from "react-icons/md";

interface Props {
    isOnEditMode: boolean;
    count: number;
    dragState: DragEvents;
    canRemove: boolean;
    onEdit: () => void;
    handleDragEvents: (event: DragEvents) => React.DragEventHandler<HTMLDivElement>;
    removeAvatar: () => Promise<void>;
}

export const EditProfileAvatarEdit = (props: Props) => {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { data: userProfileData } = useGetUserProfile(fullToken);
    const canRemove = props.canRemove && props.isOnEditMode;

    const handlePictureEditClick = () => {
        if (canRemove) {
            props.removeAvatar();
        } else {
            props.onEdit();
        }
    };

    return (
        <div
            className="edit-profile-modal_body_profile_picture"
            onDragOver={props.handleDragEvents(DragEvents.Hover)}
            onDragLeave={props.handleDragEvents(DragEvents.Enter)}
            onDrop={props.handleDragEvents(DragEvents.Drop)}
        >
            <div
                className={classNames(
                    "edit-profile-modal_drop-zone",
                    (props.dragState === DragEvents.Enter || props.dragState === DragEvents.Hover) &&
                        "edit-profile-modal_drop-zone_hovering",
                    props.dragState === DragEvents.Hover && "edit-profile-modal_drop-zone_hovering_over-element",
                )}
            >
                <div className="edit-profile-modal_drop-zone_content">
                    <AiOutlineCloudUpload className="edit-profile-modal_drop-zone_icon" />
                    <p>Drop to upload photo</p>
                </div>
            </div>
            <img
                src={userProfileData?.user.gravatar + (userProfileData?.user.profilePicture ? `?v=${props.count}` : "")}
                alt="User Profile"
            />
            <div onClick={handlePictureEditClick} className={classNames("edit-profile-modal_body_profile_picture_edit", {
                "remove": canRemove,
                "edit": !canRemove,
            })}>
                {canRemove ? <TbTrashXFilled/> : <MdEdit/>}
            </div>
        </div>
    );
};
