import {ReactElement, useEffect} from "react";
import { GoVipLevelsCardData } from "./goVipText";
import { useGate } from "statsig-react";
import classNames from "classnames";
import { GoVipLevelsLootBoxDetail } from "./GoVipLevelsLootboxDetail";

interface Props {
    levelsData: GoVipLevelsCardData;
}
const lootRewardsLevels = [3, 6, 11, 16];

export const getGoVipLevelTokenReward = (level: number) => {
    return level * 2;
};

export const getGoVipLevelRequiredXP = (level: number) => {
    return level * 1000;
};

export const getGovipLevelImage = (level: number) => {
    const gifLevels = [2];
    if (lootRewardsLevels.includes(level)) {
        return `${import.meta.env.VITE_CDN_URL}/images/LootBox.gif`;
    }
    if (gifLevels.includes(level)) {
        return `${import.meta.env.VITE_CDN_URL}/images/govip-reward-lvl${level + 1}.gif`;
    }
    return `${import.meta.env.VITE_CDN_URL}/images/govip-reward-lvl${level + 1}.webp`;
};

function GoVipLevelCard(props: Props): ReactElement {
    const { value: fuelStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const hasLootBox = lootRewardsLevels.includes(props.levelsData.level);
    const level = props.levelsData.level;

    return (
        <>
            <div className="go-vip-levels_body_cards-container_card-info">
                <span className="go-vip-levels_body_cards-container_card-info_level-chip">
                    Level {props.levelsData.level}
                </span>
                {getGoVipLevelRequiredXP(level) > 0 &&
                    <span className="go-vip-levels_body_cards-container_card-info_required-xp">
                        {getGoVipLevelRequiredXP(level)} XP Required
                    </span>
                }
            </div>
            <div className="go-vip-levels_body_cards-container_card" style={{
                marginBottom: hasLootBox ? "0.62rem" : "1.88rem",
            }}>
                <div
                    className={classNames("go-vip-levels_body_cards-container_card_body", {
                        "no-image": !getGovipLevelImage(level),
                    })}
                >
                    {getGovipLevelImage(level) &&
                        <div className="go-vip-levels_body_cards-container_card_body_img">
                            <img src={getGovipLevelImage(level)} alt={`level-${props.levelsData.level}-img`} />
                        </div>
                    }
                    <div className="go-vip-levels_body_cards-container_card_body_content">
                        <div className="go-vip-levels_body_cards-container_card_body_content_title">
                            <h6>{props.levelsData.prize}</h6>
                        </div>
                        <h6 className="go-vip-levels_body_cards-container_card_body_content_description">
                            {props.levelsData.description}
                        </h6>
                    </div>
                </div>

                {fuelStatus && getGoVipLevelTokenReward(level) > 0 &&
                    <>
                        <hr />

                        <div className="go-vip-levels_body_cards-container_card_footer">
                            <span className="go-vip-levels_body_cards-container_card_footer_bonus">Bonus</span>
                            <div className="go-vip-levels_body_cards-container_card_footer_fuel-bonus">
                                <img
                                    src={`${import.meta.env.VITE_CDN_URL}/images/govip-reward-fuel-mini-Icon.webp`}
                                    alt="fuel-bonus-icon"
                                />
                                <span>
                                    ${getGoVipLevelTokenReward(level)} {import.meta.env.VITE_TOKEN_NAME} Tokens
                                </span>
                            </div>
                        </div>
                    </>
                }
            </div>
            {hasLootBox && <GoVipLevelsLootBoxDetail level={level}/>}
        </>
    );
}

export default GoVipLevelCard;
