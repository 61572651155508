import {ReactElement, useContext, useEffect, useState} from "react";
import CheckoutDetailsHeader from "@/components/checkout/checkoutDetails/CheckoutDetailsHeader";
import { HandoffOptions, MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {addError} from "@/redux/meta/metaActions";
import CheckoutDetailsContent from "@/components/checkout/checkoutDetails/CheckoutDetailsContent";
import CheckoutDetailsHandoff from "@/components/checkout/checkoutDetails/CheckoutDetailsHandoff";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import CheckoutDetailsContactInfoModal
    from "@/components/checkout/checkoutDetails/CheckoutDetailsContactInfoModal";
import Skeleton from "@/components/skeletons/Skeleton";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import CheckoutDetailsAccountInfoModal
    from "@/components/checkout/checkoutDetails/CheckoutDetailsAccountInfoModal";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {IStore} from "@/redux/defaultStore";

function CheckoutDetails(): ReactElement {
    const { menuOrderId } = useContext(RestaurantContext);

    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: menuOrder, isLoading: isMenuOrderLoading, refetch} = useMenuOrder(menuOrderId);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const user = userProfileData?.user;
    const isMissingAccountInfo = !user?.firstName || !user?.lastName;
    const isMissingContactInfo = menuOrder && (!menuOrder?.phoneNumber || !menuOrder?.firstName || !menuOrder?.lastName);
    const [
        isHandoffUpdating,
        setIsHandoffUpdating,
    ] = useState(false);

    const [
        hasAlreadyAutoContactModal,
        setHasAlreadyAutoContactModal,
    ] = useState<boolean>(false);
    const [
        showAccountInfoModal,
        setShowAccountInfoModal,
    ] = useState<boolean>(false);
    const [
        showContactInfoModal,
        setShowContactInfoModal,
    ] = useState<boolean>(false);

    useEffect(() => {
        if (isMissingAccountInfo) {
            setShowAccountInfoModal(true);
            setHasAlreadyAutoContactModal(true);
        } else if (!hasAlreadyAutoContactModal && isMissingContactInfo) {
            setShowContactInfoModal(true);
            setHasAlreadyAutoContactModal(true);
        }
    }, [isMissingAccountInfo, isMissingContactInfo, hasAlreadyAutoContactModal]);

    /**
     * Trigger default values to be applied to menu order.
     */
    async function triggerMenuOrderUpdate(): Promise<void> {
        try {
            await new MenuOrdersApi(getConfig()).checkoutMenuOrder({
                id: menuOrder.id,
            });
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            await refetch();
        }
    }

    function onAccountInfoUpdate(saveOrderInfo: boolean) {
        setShowAccountInfoModal(false);
        if (!saveOrderInfo) {
            setShowContactInfoModal(true);
        }
    }

    return (
        <div className="checkout-details checkout-page_wrapper_content_container">
            <CheckoutDetailsAccountInfoModal
                isOpen={showAccountInfoModal}
                onDone={onAccountInfoUpdate}
            />
            <CheckoutDetailsContactInfoModal
                isOpen={showContactInfoModal}
                onClose={() => setShowContactInfoModal(false)}
            />

            <CheckoutDetailsHeader/>

            <CheckoutDetailsHandoff
                backToRestaurantPageOnAddressUpdate={false}
                onDone={triggerMenuOrderUpdate}
                isHandoffUpdating={isHandoffUpdating}
                setIsHandoffUpdating={setIsHandoffUpdating}
                menuOrderId={menuOrderId}
            />

            {isMenuOrderLoading || isHandoffUpdating
                ? <Skeleton
                    rows={menuOrder?.handoff === HandoffOptions.DELIVERY
                        ? 3
                        : 2}
                    classes="checkout-details_content_skeleton"
                />
                : <CheckoutDetailsContent setShowContactInfoModal={setShowContactInfoModal}/>
            }

        </div>
    );
}

export default CheckoutDetails;

