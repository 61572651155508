import { NftOwnershipInformation, GetGlobalOrderDiscountsResponse, OrderDiscountSearchEssential, OrderDiscount } from "@devour/client";
import { Dispatch, SetStateAction, FC, useMemo } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { RestaurantPromoCard } from "./RestaurantPromoCard";
import { ViewStates } from "../RestaurantSearchResultsPage";


interface props {
    setViewState: Dispatch<SetStateAction<ViewStates>>;
    setRestaurantIds?: Dispatch<SetStateAction<string[]>>;
    nftOwnershipResponse: NftOwnershipInformation;
    qualifiedPromos: OrderDiscountSearchEssential[];
    globalOrderDiscounts?: GetGlobalOrderDiscountsResponse;
}

export const RestaurantViewAllPromos: FC<props> = ({nftOwnershipResponse, setViewState, globalOrderDiscounts, qualifiedPromos, setRestaurantIds}) => {
    const uniqueQualifiedPromos = useMemo(() => {
        return qualifiedPromos.filter((promo, index, self) => self.findIndex(p => p.id === promo.id) === index);
    }, [qualifiedPromos]);

    const promotions = useMemo(() => {
        let allPromos = uniqueQualifiedPromos;
        if (globalOrderDiscounts?.orderDiscounts?.length > 0) {
            allPromos = [...allPromos, ...globalOrderDiscounts.orderDiscounts];
        }
        return allPromos.sort((a, b) => {
            if (a.priority !== b.priority) {
                return b.priority - a.priority;
            }
            return b.amount - a.amount;
        });

    }, [globalOrderDiscounts, uniqueQualifiedPromos]);
    return (
        <div className="restaurant-view-all-promos">
            <div className="restaurant-view-all-promos_header">
                <FiChevronLeft onClick={() => setViewState(ViewStates.VIEW_ALL)}/>
                <h3>All Promotions</h3>
                <span>{promotions.length}</span>
            </div>

            <div className="restaurant-view-all-promos_list">
                {promotions.map((promo: OrderDiscount) =>
                    <RestaurantPromoCard
                        key={promo.id}
                        promo={promo}
                        nftOwnershipResponse={nftOwnershipResponse}
                        onOrderNow={() => {
                            setViewState(ViewStates.PROMO_RESTAURANTS);
                            setRestaurantIds?.(promo.businesses);
                        }}
                    />)}
            </div>
        </div>
    );
};
