import { ReactElement } from "react";

function DevourPlayLandingPage(): ReactElement {
    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                margin: 0,
                padding: 0,
                overflow: "hidden", // Ensures no scrolling on the parent container
            }}
        >
            <iframe
                src={`https://devour.io/devourplay-landing/?t=${Date.now()}`}
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none", // Removes iframe border
                    display: "block", // Removes inline spacing
                    overflow: "auto", // Allows scrolling within the iframe
                }}
            />
        </div>
    );
}

export default DevourPlayLandingPage;
