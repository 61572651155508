/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWEventTypeResponse,
    OWEventTypeResponseFromJSON,
    OWEventTypeResponseFromJSONTyped,
    OWEventTypeResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {Array<OWEventTypeResponse>}
     * @memberof InlineResponse20016
     */
    eventTypes: Array<OWEventTypeResponse>;
}

export function InlineResponse20016FromJSON(json: any): InlineResponse20016 {
    return InlineResponse20016FromJSONTyped(json, false);
}

export function InlineResponse20016FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20016 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventTypes': ((json['eventTypes'] as Array<any>).map(OWEventTypeResponseFromJSON)),
    };
}

export function InlineResponse20016ToJSON(value?: InlineResponse20016 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventTypes': ((value.eventTypes as Array<any>).map(OWEventTypeResponseToJSON)),
    };
}


