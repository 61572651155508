/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EVMChain {
    ETHEREUMMAINNET = 'ETHEREUM_MAINNET',
    POLYGON = 'POLYGON',
    AVALANCHE = 'AVALANCHE',
    MUMBAI = 'MUMBAI',
    BASE = 'BASE',
    SOLANA = 'SOLANA'
}

export function EVMChainFromJSON(json: any): EVMChain {
    return EVMChainFromJSONTyped(json, false);
}

export function EVMChainFromJSONTyped(json: any, ignoreDiscriminator: boolean): EVMChain {
    return json as EVMChain;
}

export function EVMChainToJSON(value?: EVMChain | null): any {
    return value as any;
}

