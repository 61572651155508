/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWRewardType,
    OWRewardTypeFromJSON,
    OWRewardTypeFromJSONTyped,
    OWRewardTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface RewardResponseObject
 */
export interface RewardResponseObject {
    /**
     * 
     * @type {OWRewardType}
     * @memberof RewardResponseObject
     */
    rewardType: OWRewardType;
    /**
     * 
     * @type {number}
     * @memberof RewardResponseObject
     */
    amount: number;
}

export function RewardResponseObjectFromJSON(json: any): RewardResponseObject {
    return RewardResponseObjectFromJSONTyped(json, false);
}

export function RewardResponseObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): RewardResponseObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rewardType': OWRewardTypeFromJSON(json['rewardType']),
        'amount': json['amount'],
    };
}

export function RewardResponseObjectToJSON(value?: RewardResponseObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rewardType': OWRewardTypeToJSON(value.rewardType),
        'amount': value.amount,
    };
}


