import {LootboxReward, NftsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";

async function fetchLootboxNftRewards(): Promise<Array<LootboxReward>> {
    return await new NftsApi(getConfig()).getLootboxNftRewards();
}

function getLootboxNftRewardsQuery() {
    return {
        queryKey: ["lootbox-nft-rewards"],
        queryFn: () => fetchLootboxNftRewards(),
        keepPreviousData: true,
        staleTime: 30 * 24 * 60 * 60000,
        refetchOnWindowFocus: false,
    } as const;
}

export function useGetLootboxNftRewards() {
    return useQuery(getLootboxNftRewardsQuery());
}