import { LandingPageQuestionCard } from "./LandingPageQuestionCard";

export const LandingPageFaq = () => {
    const faq = [
        {
            question: "What is DevourPlay?",
            answer:
                <>
                    DevourPlay is an app built on the Overwolf platform that transforms your gaming time into tangible
                    rewards. By tracking your in-game achievements, time played, and quest completions, DevourPlay lets
                    you earn XP, $FUELX tokens, and exclusive promotions while you game. These rewards can be used for
                    food orders, gift cards, and more.
                    <br />
                    <br />
                    DevourPlay is designed to enhance your gaming experience by creating engaging quests, streak
                    bonuses, and challenges across a variety of games. It’s not just about playing—it’s about leveling
                    up in real life while you dominate the virtual world. Plus, with features like dynamic reward badges
                    and creative quest cycles, DevourPlay makes every gaming session rewarding and exciting.
                </>
            ,
        },
        {
            question: "How do I access DevourPlay?",
            answer:
                <>
                    <p>
                        DevourPlay is accessed from within the Overwolf Store to install and then can be found in your
                        Library or Overwolf Dock.
                    </p>
                    <ul>
                        <li>Launch Overwolf: Launch the Overwolf application on your PC.</li>
                        <li>Navigate to the Store: Search for DevourPlay.</li>
                        <li>
                            Install the app: Hover over the DevourPlay tile, then click the Download App button to
                            install.
                        </li>
                    </ul>
                    <p>
                        Once DevourPlay is installed you can access it via CTRL-D while gaming or via your dock to
                        launch.
                    </p>
                </>
            ,
        },
        {
            question: "How do I order food while I game?",
            answer:
                <>
                    <p>
                        Ordering in <strong>DevourPlay</strong> is seamless, rewarding, and gamer-friendly! Here’s how
                        it works:
                    </p>
                    <ol>
                        <li>
                            <strong>Access EAT in the DevourPlay App</strong>: This connects you to a wide network of
                            restaurants and merchants in your area.
                        </li>
                        <li>
                            <strong>Place Your Order</strong>: Browse menus, customize your meal, and complete your
                            order—all without leaving the app. Choose delivery or pickup, depending on your preference.
                        </li>
                        <li>
                            <strong>Enjoy and Power Up</strong>: Get your food delivered to fuel your next gaming
                            session without interrupting the grind, all while earning more XP for your order!
                        </li>
                    </ol>
                </>
            ,
        },
        {
            question: "What does XP get me in DevourPlay?",
            answer:
                <>
                    <p>
                        <strong>XP (Experience Points)</strong> in <strong>DevourPlay</strong> is the ultimate measure
                        of your gaming achievements and progression. It’s your gateway to leveling up, unlocking
                        rewards, and getting real-world perks for your in-game dedication. Here’s everything you need to
                        know about XP:
                    </p>
                    <p>
                        <strong>How XP Works</strong>:
                    </p>
                    <ul>
                        <li>
                            <strong>Earn XP by Playing</strong>: Rack up XP through game time, completing quests, and
                            hitting milestones.
                        </li>
                        <li>
                            <strong>Level Up</strong>: Each level requires{" "}
                            <strong>1,000 XP multiplied by your current level number</strong>
                            (e.g., Level 2 needs 2,000 XP). Every level unlocks bigger and better rewards.
                        </li>
                    </ul>
                    <p>
                        <strong>What Can You Do with XP?</strong>:
                    </p>
                    <ul>
                        <li>
                            <strong>Claim Real Rewards</strong>: Redeem XP for <strong>$FUELX tokens</strong>,
                            discounts, loot boxes, gift cards, merchandise, and more.
                        </li>
                        <li>
                            <strong>Track Progress</strong>: XP reflects your gaming grind, showing off your
                            achievements and dedication.
                        </li>
                        <li>
                            <strong>Stay Motivated</strong>: With XP tied to real-life rewards, every hour and every
                            quest feels meaningful.
                        </li>
                    </ul>
                    <p>
                        <strong>Why Gamers Love XP</strong>:
                    </p>
                    <p>
                        XP isn’t just a number—it’s proof of your gaming prowess. It fuels your climb to the next level
                        while earning you perks to enhance your gaming lifestyle. In DevourPlay, your skills and time
                        translate into tangible rewards. 🎮✨
                    </p>
                </>
            ,
        },
    ];
    return (
        <div className="landing_got-questions_questions">
            {faq.map((faqData) =>
                <LandingPageQuestionCard key={faqData.question}
                    answer={faqData.answer}
                    question={faqData.question} />)}
        </div>
    );
};
