import {ReactElement, useContext, useState, MouseEvent, ButtonHTMLAttributes, useEffect} from "react";
import {
    MenuItem,
    GetMenuResponse,
    MenuOrderItem,
} from "@devour/client";
import classNames from "classnames";
import FrameButton from "../../../components/buttons/FrameButton";
import MenuItemCustomizationModal from "../../../components/modals/MenuItemCustomizationModal";
import {FaAngleDown} from "react-icons/fa";
import MenuItemCardNftGroupings from "./MenuItemCardNftGroupings";
import {isDesktop} from "react-device-detect";
import {RestaurantContext} from "../context/RestaurantContext";
import MenuItemCardAddButton from "./MenuItemCardAddButton";
import {htmlDecode} from "@/utils/htmlDecode";
import {isRestaurantAvailable, useRestaurantAvailability} from "@/hooks/useRestaurantAvailability";
import {useRestaurant} from "@/hooks/useRestaurant";
import {getUniqueIDForMenuOrderItem} from "@/utils/restaurantPageHelperFunctions";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

interface Props {
    placeId: string;
    restaurantMenu: GetMenuResponse;
    menuItem: MenuItem;
    updateMenuOrderItemManager: (key: MenuOrderItem[]) => void;
    menuOrderItemsManager: { [key: string]: MenuOrderItem };
}

function MenuItemCard(props: Props): ReactElement {
    const {restaurantId, activeMenuItemId} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId, props.placeId);
    const [
        showCustomizationModal,
        setShowCustomizationModal,
    ] = useState<boolean>(activeMenuItemId === props.menuItem.id);
    const [
        lockNftGroupings,
        setLockNftGroupings,
    ] = useState<boolean>(false);
    const [
        hoverNftGroupings,
        setHoverNftGroupings,
    ] = useState<boolean>(false);
    const {data: restaurantAvailability} = useRestaurantAvailability(restaurant);
    const availabilityCheckFailed = !isRestaurantAvailable(restaurantAvailability);
    const isMenuOrderUpdating = useSelector((store: IStore) => store.metaStore.isMenuOrderUpdating);

    function toggleCustomizationModal(open: boolean = false): void {
        setShowCustomizationModal(open);
    }

    function onFinishedUpdatingMenuItemFromModal(menuOrderItem: MenuOrderItem): void {
        const id: string = getUniqueIDForMenuOrderItem(menuOrderItem);
        const originalItem = props.menuOrderItemsManager ? props.menuOrderItemsManager[id] : undefined;
        if (originalItem) {
            // This item already exists, update its quantity instead
            props.updateMenuOrderItemManager([{
                ...originalItem,
                quantity: originalItem.quantity + menuOrderItem.quantity,
            }]);
        } else {
            props.updateMenuOrderItemManager([menuOrderItem]);
        }
        toggleCustomizationModal();
    }

    function toggleLockNftGroupings(event: MouseEvent<HTMLButtonElement>): void {
        setLockNftGroupings(s => !s);
        event.stopPropagation();
    }

    function cardOnClick(event: MouseEvent<HTMLDivElement>): void {
        event?.stopPropagation();
        if (availabilityCheckFailed || isMenuOrderUpdating) {
            return;
        }
        toggleCustomizationModal(true);
    }

    function toggleHoverNftGroupings(): void {
        if (isDesktop) {
            setHoverNftGroupings(s => !s);
        }
    }

    if (!props.menuItem || !props.restaurantMenu || !restaurant) {
        return null;
    }

    return (
        <>
            <MenuItemCustomizationModal
                isOpen={showCustomizationModal}
                placeId={props.placeId}
                restaurantId={restaurant.id}
                restaurantMenu={props.restaurantMenu}
                menuItem={props.menuItem}
                onClose={toggleCustomizationModal}
                onDone={onFinishedUpdatingMenuItemFromModal}
            />

            <div
                className={classNames("menu-item-card", {
                    "is-enabled": restaurant.isOpen && props.menuItem.isEnabled,
                    "is-lock-nft": lockNftGroupings,
                    "is-hover-nft": hoverNftGroupings,
                })}
                onClick={cardOnClick}
            >

                <div className="menu-item-card_left">
                    {props.menuItem.nftGroupings?.length > 0 &&
                    <div className="menu-item-card_secret-item">
						    <FrameButton
						        <ButtonHTMLAttributes<HTMLButtonElement>>
						        onClick={toggleLockNftGroupings}
						        color="purple"
						        size="pill"
						        forwardProps={{
						            onMouseEnter: toggleHoverNftGroupings,
						            onMouseLeave: toggleHoverNftGroupings,
						        }}
						    >
								Secret
						    </FrameButton>
                    </div>
                    }
                    <div>
                        <div className="menu-item-card_header">
                            <div className="menu-item-card_name">
                                {htmlDecode(props.menuItem?.name)}
                            </div>
                            {props.menuItem.nftGroupings?.length > 0 &&
                            <button
								    title="Secret item NFT requirements"
								    className="reset-button"
								    onClick={toggleLockNftGroupings}
								    onMouseEnter={toggleHoverNftGroupings}
								    onMouseLeave={toggleHoverNftGroupings}
                            >
								    <FaAngleDown/>
                            </button>
                            }
                        </div>

                        <div className="menu-item-card_description">
                            {htmlDecode(props.menuItem.description)}
                        </div>
                    </div>

                    <div className={classNames("menu-item-card_price", {
                        "is-free": props.menuItem.price <= 0,
                    })}
                    >
                        {props.menuItem.price > 0
                            ? `$${props.menuItem.price.toFixed(2)}`
                            : "$0.00"}
                    </div>

                    <MenuItemCardNftGroupings
                        menuItem={props.menuItem}
                    />

                </div>

                <div className="menu-item-card_right">
                    {props.menuItem.images?.length > 0 &&
                    <div className="menu-item-card_image">
                        <div className="menu-item-card_image_inner">
                            <img
                                src={props.menuItem?.images[0].url}
                                alt={props.menuItem?.name}
                                onError={({currentTarget}) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = `${import.meta.env.VITE_CDN_URL}/images/empty.png`;
                                }}
                            />
                        </div>
                    </div>
                    }

                    <MenuItemCardAddButton
                        menuItem={props.menuItem}
                        setShowCustomizationModal={setShowCustomizationModal}
                        placeId={props.placeId}
                        updateMenuOrderItemManager={props.updateMenuOrderItemManager}
                        menuOrderItemsManager={props.menuOrderItemsManager}
                    />
                </div>

            </div>
        </>
    );
}

export default MenuItemCard;
