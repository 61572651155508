/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOverwolfQuestsResponseAllOf,
    GetOverwolfQuestsResponseAllOfFromJSON,
    GetOverwolfQuestsResponseAllOfFromJSONTyped,
    GetOverwolfQuestsResponseAllOfToJSON,
    OWEventTypeResponse,
    OWEventTypeResponseFromJSON,
    OWEventTypeResponseFromJSONTyped,
    OWEventTypeResponseToJSON,
    OWGameResponse,
    OWGameResponseFromJSON,
    OWGameResponseFromJSONTyped,
    OWGameResponseToJSON,
    OWQuestResponse,
    OWQuestResponseFromJSON,
    OWQuestResponseFromJSONTyped,
    OWQuestResponseToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOverwolfQuestsResponse
 */
export interface GetOverwolfQuestsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<OWQuestResponse>}
     * @memberof GetOverwolfQuestsResponse
     */
    quests: Array<OWQuestResponse>;
    /**
     * 
     * @type {Array<OWEventTypeResponse>}
     * @memberof GetOverwolfQuestsResponse
     */
    allEventTypes: Array<OWEventTypeResponse>;
    /**
     * 
     * @type {Array<OWGameResponse>}
     * @memberof GetOverwolfQuestsResponse
     */
    allGames?: Array<OWGameResponse>;
}

export function GetOverwolfQuestsResponseFromJSON(json: any): GetOverwolfQuestsResponse {
    return GetOverwolfQuestsResponseFromJSONTyped(json, false);
}

export function GetOverwolfQuestsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOverwolfQuestsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'quests': ((json['quests'] as Array<any>).map(OWQuestResponseFromJSON)),
        'allEventTypes': ((json['allEventTypes'] as Array<any>).map(OWEventTypeResponseFromJSON)),
        'allGames': !exists(json, 'allGames') ? undefined : ((json['allGames'] as Array<any>).map(OWGameResponseFromJSON)),
    };
}

export function GetOverwolfQuestsResponseToJSON(value?: GetOverwolfQuestsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'quests': ((value.quests as Array<any>).map(OWQuestResponseToJSON)),
        'allEventTypes': ((value.allEventTypes as Array<any>).map(OWEventTypeResponseToJSON)),
        'allGames': value.allGames === undefined ? undefined : ((value.allGames as Array<any>).map(OWGameResponseToJSON)),
    };
}


