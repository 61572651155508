/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    GetNftPurchasesResponse,
    GetNftPurchasesResponseFromJSON,
    GetNftPurchasesResponseToJSON,
    GetUnclaimedSupplyResponse,
    GetUnclaimedSupplyResponseFromJSON,
    GetUnclaimedSupplyResponseToJSON,
    InlineResponse20018,
    InlineResponse20018FromJSON,
    InlineResponse20018ToJSON,
    NftPurchaseRequestBody,
    NftPurchaseRequestBodyFromJSON,
    NftPurchaseRequestBodyToJSON,
    NftPurchaseUpsert,
    NftPurchaseUpsertFromJSON,
    NftPurchaseUpsertToJSON,
} from '../models';

export interface GetNftPurchaseRequest {
    id: string;
}

export interface GetNftPurchasesRequest {
    search?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface SubmitNftPurchaseRequest {
    id: string;
}

export interface UpsertNftPurchaseRequest {
    nftPurchaseRequestBody?: NftPurchaseRequestBody;
}

export interface VerifyNftPurchaseRequest {
    id: string;
}

/**
 * ThirdwebApi - interface
 * @export
 * @interface ThirdwebApiInterface
 */
export interface ThirdwebApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdwebApiInterface
     */
    getNftPurchaseRaw(requestParameters: GetNftPurchaseRequest): Promise<runtime.ApiResponse<NftPurchaseUpsert>>;

    /**
     */
    getNftPurchase(requestParameters: GetNftPurchaseRequest): Promise<NftPurchaseUpsert>;

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdwebApiInterface
     */
    getNftPurchasesRaw(requestParameters: GetNftPurchasesRequest): Promise<runtime.ApiResponse<GetNftPurchasesResponse>>;

    /**
     */
    getNftPurchases(requestParameters: GetNftPurchasesRequest): Promise<GetNftPurchasesResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdwebApiInterface
     */
    getNftUnclaimedSupplyRaw(): Promise<runtime.ApiResponse<GetUnclaimedSupplyResponse>>;

    /**
     */
    getNftUnclaimedSupply(): Promise<GetUnclaimedSupplyResponse>;

    /**
     * Used to mint a NFT
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdwebApiInterface
     */
    submitNftPurchaseRaw(requestParameters: SubmitNftPurchaseRequest): Promise<runtime.ApiResponse<InlineResponse20018>>;

    /**
     * Used to mint a NFT
     */
    submitNftPurchase(requestParameters: SubmitNftPurchaseRequest): Promise<InlineResponse20018>;

    /**
     * Used to get information about the next claimable NFT.
     * @param {NftPurchaseRequestBody} [nftPurchaseRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdwebApiInterface
     */
    upsertNftPurchaseRaw(requestParameters: UpsertNftPurchaseRequest): Promise<runtime.ApiResponse<NftPurchaseUpsert>>;

    /**
     * Used to get information about the next claimable NFT.
     */
    upsertNftPurchase(requestParameters: UpsertNftPurchaseRequest): Promise<NftPurchaseUpsert>;

    /**
     * Verify that the payment has been successfully processed and mints the NFT to the customer\'s wallet.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdwebApiInterface
     */
    verifyNftPurchaseRaw(requestParameters: VerifyNftPurchaseRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Verify that the payment has been successfully processed and mints the NFT to the customer\'s wallet.
     */
    verifyNftPurchase(requestParameters: VerifyNftPurchaseRequest): Promise<void>;

}

/**
 * no description
 */
export class ThirdwebApi extends runtime.BaseAPI implements ThirdwebApiInterface {

    /**
     */
    async getNftPurchaseRaw(requestParameters: GetNftPurchaseRequest): Promise<runtime.ApiResponse<NftPurchaseUpsert>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNftPurchase.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/thirdweb/nft-purchases/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NftPurchaseUpsertFromJSON(jsonValue));
    }

    /**
     */
    async getNftPurchase(requestParameters: GetNftPurchaseRequest): Promise<NftPurchaseUpsert> {
        const response = await this.getNftPurchaseRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNftPurchasesRaw(requestParameters: GetNftPurchasesRequest): Promise<runtime.ApiResponse<GetNftPurchasesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/thirdweb/nft-purchases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftPurchasesResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNftPurchases(requestParameters: GetNftPurchasesRequest): Promise<GetNftPurchasesResponse> {
        const response = await this.getNftPurchasesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNftUnclaimedSupplyRaw(): Promise<runtime.ApiResponse<GetUnclaimedSupplyResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/thirdweb/nft-unclaimed_supply`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUnclaimedSupplyResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNftUnclaimedSupply(): Promise<GetUnclaimedSupplyResponse> {
        const response = await this.getNftUnclaimedSupplyRaw();
        return await response.value();
    }

    /**
     * Used to mint a NFT
     */
    async submitNftPurchaseRaw(requestParameters: SubmitNftPurchaseRequest): Promise<runtime.ApiResponse<InlineResponse20018>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitNftPurchase.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/thirdweb/nft-purchases/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20018FromJSON(jsonValue));
    }

    /**
     * Used to mint a NFT
     */
    async submitNftPurchase(requestParameters: SubmitNftPurchaseRequest): Promise<InlineResponse20018> {
        const response = await this.submitNftPurchaseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get information about the next claimable NFT.
     */
    async upsertNftPurchaseRaw(requestParameters: UpsertNftPurchaseRequest): Promise<runtime.ApiResponse<NftPurchaseUpsert>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/thirdweb/upsert-nft-purchase`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NftPurchaseRequestBodyToJSON(requestParameters.nftPurchaseRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NftPurchaseUpsertFromJSON(jsonValue));
    }

    /**
     * Used to get information about the next claimable NFT.
     */
    async upsertNftPurchase(requestParameters: UpsertNftPurchaseRequest): Promise<NftPurchaseUpsert> {
        const response = await this.upsertNftPurchaseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Verify that the payment has been successfully processed and mints the NFT to the customer\'s wallet.
     */
    async verifyNftPurchaseRaw(requestParameters: VerifyNftPurchaseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling verifyNftPurchase.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/thirdweb/nft-purchases/{id}/verify`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify that the payment has been successfully processed and mints the NFT to the customer\'s wallet.
     */
    async verifyNftPurchase(requestParameters: VerifyNftPurchaseRequest): Promise<void> {
        await this.verifyNftPurchaseRaw(requestParameters);
    }

}
