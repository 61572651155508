/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    BonusDpayOption,
    BonusDpayOptionFromJSON,
    BonusDpayOptionFromJSONTyped,
    BonusDpayOptionToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
    SiteConfigurationKey,
    SiteConfigurationKeyFromJSON,
    SiteConfigurationKeyFromJSONTyped,
    SiteConfigurationKeyToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Used for site-wide configuration like promotional banners and reward management.
 * @export
 * @interface SiteConfiguration
 */
export interface SiteConfiguration {
    /**
     * 
     * @type {string}
     * @memberof SiteConfiguration
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof SiteConfiguration
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof SiteConfiguration
     */
    updatedAt: number;
    /**
     * 
     * @type {SiteConfigurationKey}
     * @memberof SiteConfiguration
     */
    configKey: SiteConfigurationKey;
    /**
     * 
     * @type {Asset}
     * @memberof SiteConfiguration
     */
    asset?: Asset;
    /**
     * 
     * @type {string}
     * @memberof SiteConfiguration
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfiguration
     */
    buttonText?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfiguration
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfiguration
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof SiteConfiguration
     */
    data?: object;
    /**
     * 
     * @type {number}
     * @memberof SiteConfiguration
     */
    percentage?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteConfiguration
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteConfiguration
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteConfiguration
     */
    business?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteConfiguration
     */
    nftGroupings?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SiteConfiguration
     */
    startDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteConfiguration
     */
    endDate?: number;
    /**
     * 
     * @type {BonusDpayOption}
     * @memberof SiteConfiguration
     */
    bonusDpayType?: BonusDpayOption;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof SiteConfiguration
     */
    phoneNumber?: PhoneNumber;
    /**
     * Indicates if the current date time falls within the configuration\'s start and end dates
     * @type {boolean}
     * @memberof SiteConfiguration
     */
    isWithinValidDate?: boolean;
}

export function SiteConfigurationFromJSON(json: any): SiteConfiguration {
    return SiteConfigurationFromJSONTyped(json, false);
}

export function SiteConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'configKey': SiteConfigurationKeyFromJSON(json['configKey']),
        'asset': !exists(json, 'asset') ? undefined : AssetFromJSON(json['asset']),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'buttonText': !exists(json, 'buttonText') ? undefined : json['buttonText'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'business': !exists(json, 'business') ? undefined : json['business'],
        'nftGroupings': !exists(json, 'nftGroupings') ? undefined : json['nftGroupings'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'bonusDpayType': !exists(json, 'bonusDpayType') ? undefined : BonusDpayOptionFromJSON(json['bonusDpayType']),
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberFromJSON(json['phoneNumber']),
        'isWithinValidDate': !exists(json, 'isWithinValidDate') ? undefined : json['isWithinValidDate'],
    };
}

export function SiteConfigurationToJSON(value?: SiteConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'configKey': SiteConfigurationKeyToJSON(value.configKey),
        'asset': AssetToJSON(value.asset),
        'url': value.url,
        'buttonText': value.buttonText,
        'title': value.title,
        'description': value.description,
        'data': value.data,
        'percentage': value.percentage,
        'amount': value.amount,
        'user': value.user,
        'business': value.business,
        'nftGroupings': value.nftGroupings,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'bonusDpayType': BonusDpayOptionToJSON(value.bonusDpayType),
        'phoneNumber': PhoneNumberToJSON(value.phoneNumber),
        'isWithinValidDate': value.isWithinValidDate,
    };
}


