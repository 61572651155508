import { ReactElement, useEffect, useState } from "react";
import FrameButton from "../buttons/FrameButton";
import { GoVipLevelsCardData } from "../goVip/goVipText";
import { useNavigate } from "react-router";
import { HiSparkles } from "react-icons/hi2";
import useThemePreference from "@/hooks/useThemePreference";
import { useGate } from "statsig-react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";
import { MdClose } from "react-icons/md";
import GoVipLevelCard from "@/components/goVip/GoVipLevelCard";

export const getGoVipLevelsText = (dpayStatus: boolean) => {
    const getFuelTokenText = (amount: number, title: boolean = true) => {
        if (title) {
            return dpayStatus ? `$${amount} ${import.meta.env.VITE_TOKEN_NAME} Tokens` : "N/A";
        }
        return dpayStatus
            ? `Earn $${amount} worth of ${
                import.meta.env.VITE_TOKEN_NAME
            } Tokens that you can use as a payment method to place your order!`
            : "N/A";
    };
    return [
        {
            level: 1,
            prize: "$5 OFF Promo Code",
            description: "Earn $5 OFF discount code you can use when placing your food order",
        },
        {
            level: 2,
            prize: "Infinite Promo Access Pass",
            description: "Get exclusive access to food promotions every time we roll out new deals",
        },
        {
            level: 3,
            prize: "Loot Box #1",
            description: "You may be able to unlock FUEL rewards, free trips, electronics, or other exclusive prizes",
        },
        {
            level: 4,
            prize: "Devour T-Shirt",
            description: "Get a free exclusive T-shirt from Devour! It's your badge in our community",
        },
        {
            level: 5,
            prize: "$10 OFF Promo Code",
            description: "Earn $10 OFF discount code you can use when placing your food order",
        },
        {
            level: 6,
            prize: "Loot Box #2",
            description: "You may be able to unlock FUEL rewards, free trips, electronics, or other exclusive prizes",
        },
        {
            level: 7,
            prize: "$10 Steam Card",
            description: "Get a $10 Steam gift card that you can use to purchase games",
        },
        {
            level: 8,
            prize: "Devour Sweatshirt",
            description: "Get a free exclusive sweatshirt from Devour! It's your badge in our community",
        },
        {
            level: 9,
            prize: getFuelTokenText(20),
            description: getFuelTokenText(20, false),
        },
        {
            level: 10,
            prize: "$20 OFF Promo Code",
            description: "Earn $20 OFF discount code you can use when placing your food order",
        },
        {
            level: 11,
            prize: "Loot Box #3",
            description: "You may be able to unlock FUEL rewards, free trips, electronics, or other exclusive prizes",
        },
        {
            level: 12,
            prize: "$20 Steam Card",
            description: "Get a $20 Steam gift card that you can use to purchase games",
        },
        {
            level: 13,
            prize: "DevourPlay Cap",
            description: "Get a free exclusive DevourPlay cap from Devour! It's your badge in our community.",
        },
        {
            level: 14,
            prize: "$30 Off Promo Code",
            description: "Earn $30 OFF discount code you can use when placing your food order",
        },
        {
            level: 15,
            prize: getFuelTokenText(25),
            description: getFuelTokenText(25, false),
        },
        {
            level: 16,
            prize: "Loot Box #4",
            description: "You may be able to unlock FUEL rewards, free trips, electronics, or other exclusive prizes",
        },
        {
            level: 17,
            prize: "$30 Steam Card",
            description: "Get a $30 Steam gift card that you can use to purchase games",
        },
        {
            level: 18,
            prize: "$40 OFF Promo Code",
            description: "Earn $40 OFF discount code you can use when placing your food order",
        },
        {
            level: 19,
            prize: getFuelTokenText(30),
            description: getFuelTokenText(30, false),
        },
        {
            level: 20,
            prize: "DevourPlay Gamer Chef Bobblehead",
            description: "Get a free exclusive DevourPlay Chef bobblehead from Devour!",
        },
    ];
};

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function GoVipLevelsModal(props: Props): ReactElement {
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const { isOnDarkMode } = useThemePreference();
    const [isScrollerAtTop, setIsScrollerAtTop] = useState(true);
    const navigate = useNavigate();

    const goVipLevelsText = getGoVipLevelsText(dpayStatus);

    function renderLevelCards(levelData: Array<GoVipLevelsCardData>) {
        return levelData.map((level) => <GoVipLevelCard levelsData={level} key={`govip-level-card-${level.level}`} />);
    }

    useEffect(() => {
        if (props.isOpen) {
            setIsScrollerAtTop(true);
        }
    }, [props.isOpen]);

    const handleScrollerScroll: React.UIEventHandler<HTMLDivElement> = (ev) => {
        setIsScrollerAtTop(ev.currentTarget.scrollTop <= 5);
    };

    return (
        <>
            <FrameOneModal isOpen={props.isOpen} toggle={props.toggle} size="xs2" contentClassName="go-vip-levels">
                <div className={classNames("go-vip-levels_close-icon", {
                    "is-floating": !isScrollerAtTop,
                })} onClick={props.toggle}>
                    <MdClose/>
                </div>
                <div
                    className={classNames("go-vip-levels_scroller", {
                        "is-desktop": isDesktop,
                    })}
                    onScroll={handleScrollerScroll}
                >

                    <div className="go-vip-levels_body">
                        <h3 className="go-vip-levels_body_title">LEVEL REWARDS</h3>
                        <p className="go-vip-levels_body_description">Earn rewards when you complete level</p>

                        <div className={classNames("go-vip-levels_body_cards-container")}>
                            {renderLevelCards(goVipLevelsText.slice(0, 3))}

                            <div className="go-vip-levels_body_cards-container_prompt-container">
                                <div className="go-vip-levels_body_cards-container_prompt-container_title">
                                    <HiSparkles />
                                    <div>
                                        <p className="go-vip-levels_body_cards-container_prompt-container_title_text">
                                            Ready to hit Level 4?
                                        </p>
                                        <p className="go-vip-levels_body_cards-container_prompt-container_title_text">
                                            Grab the <span className="purle-text">Industry Pass</span> and keep the
                                            grind going!
                                        </p>
                                    </div>
                                </div>

                                <div className="go-vip-levels_body_cards-container_prompt-container_mint-container">
                                    <FrameButton
                                        color={isOnDarkMode ? "devourplay-gradient" : "white-drop-shadow-devour"}
                                        size="narrow"
                                        className="go-vip-levels_body_cards-container_prompt-container_mint-container_button"
                                        onClick={() => navigate("/the-industry-pass")}
                                    >
                                        Unlock Level 4
                                    </FrameButton>
                                </div>
                            </div>

                            {renderLevelCards(goVipLevelsText.slice(3, goVipLevelsText.length))}
                        </div>
                    </div>
                </div>
            </FrameOneModal>
        </>
    );
}

export default GoVipLevelsModal;
