import {useQuery} from "@tanstack/react-query";
import {Asset, AssetsApi} from "@devour/client";

async function fetchAsset(assetId: string): Promise<Asset> {
    if (!assetId) {
        return;
    }

    return await new AssetsApi().getAsset({
        id: assetId,
    });
}

function assetQuery(assetId: string) {
    return {
        queryKey: [
            "asset",
            assetId,
        ],
        queryFn: () => fetchAsset(assetId),
        keepPreviousData: true,
        staleTime: 60000,
        refetchOnWindowFocus: false,
        enabled: Boolean(assetId),
    } as const;
}

export function useAsset(assetId: string) {
    // const queryClient = useQueryClient();
    console.log({assetId});
    return useQuery(assetQuery(assetId));
}

