/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWGameResponse,
    OWGameResponseFromJSON,
    OWGameResponseFromJSONTyped,
    OWGameResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {Array<OWGameResponse>}
     * @memberof InlineResponse20017
     */
    games: Array<OWGameResponse>;
}

export function InlineResponse20017FromJSON(json: any): InlineResponse20017 {
    return InlineResponse20017FromJSONTyped(json, false);
}

export function InlineResponse20017FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20017 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'games': ((json['games'] as Array<any>).map(OWGameResponseFromJSON)),
    };
}

export function InlineResponse20017ToJSON(value?: InlineResponse20017 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'games': ((value.games as Array<any>).map(OWGameResponseToJSON)),
    };
}


