/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetNftRewardResponse,
    GetNftRewardResponseFromJSON,
    GetNftRewardResponseFromJSONTyped,
    GetNftRewardResponseToJSON,
    LootboxRewardAllOf,
    LootboxRewardAllOfFromJSON,
    LootboxRewardAllOfFromJSONTyped,
    LootboxRewardAllOfToJSON,
    NftRewardMetadata,
    NftRewardMetadataFromJSON,
    NftRewardMetadataFromJSONTyped,
    NftRewardMetadataToJSON,
    NftRewardPrize,
    NftRewardPrizeFromJSON,
    NftRewardPrizeFromJSONTyped,
    NftRewardPrizeToJSON,
} from './';

/**
 * 
 * @export
 * @interface LootboxReward
 */
export interface LootboxReward extends GetNftRewardResponse {
    /**
     * 
     * @type {number}
     * @memberof LootboxReward
     */
    levelCompleted?: number;
}

export function LootboxRewardFromJSON(json: any): LootboxReward {
    return LootboxRewardFromJSONTyped(json, false);
}

export function LootboxRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): LootboxReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...GetNftRewardResponseFromJSONTyped(json, ignoreDiscriminator),
        'levelCompleted': !exists(json, 'levelCompleted') ? undefined : json['levelCompleted'],
    };
}

export function LootboxRewardToJSON(value?: LootboxReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...GetNftRewardResponseToJSON(value),
        'levelCompleted': value.levelCompleted,
    };
}


