import { useMemo } from "react";

export const LandingPageImagesCarousel = () => {
    const images = useMemo(() => {
        return Array.from({length: 16}, (_, i) => {
            return {
                url: `${import.meta.env.VITE_CDN_URL}/images/lp-devourplay-carousel-img-${i + 1}.png`,
                alt: `landing-img-${i + 1}`,
            };
        });
    }, []);
    return (
        <div className="landing-page-images-carousel">
            <div className="landing-page-images-carousel_scroll-container">
                {[...images, ...images].map((image, index) =>
                    <img
                        key={index}
                        src={image.url}
                        alt={image.alt}
                        className="landing-page-images-carousel_scroll-container_image"
                    />)}
            </div>
        </div>
    );
};
