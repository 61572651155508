import {ReactElement, ReactNode, useState} from "react";
import {useDispatch} from "react-redux";
import {ApiError} from "@devour/client";
import {decrementModalCount, removeError} from "@/redux/meta/metaActions";
import FrameOneAutoPanel from "../autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "../autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "../autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "../autoPanelComponents/FrameAutoPanelFooter";
import FrameButton from "../../buttons/FrameButton";
import {isFormError} from "@/typeguards/isFormError";
import ReportErrorModal from "@/components/modals/errorManager/ReportErrorModal";

interface Props {
    index: number;
    apiError: ApiError;
}

function ErrorModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [
        showReportErrorModal,
        setShowReportErrorModal,
    ] = useState<boolean>(false);

    /**
     * Dismiss this specific error modal.
     *
     */
    function dismiss(): void {
        // Need to decrement the modal count twice because this error modal increments twice for some reason.
        dispatch(decrementModalCount());
        dispatch(decrementModalCount());
        dispatch(removeError(props.index));
    }

    /**
     * Render all errors & sub-errors.
     *
     * @param error
     * @param i
     */
    function createErrors(error: ApiError, i: number): ReactNode {
        return (
            <li key={`error_${i}`}>
                {error.message}
                {Array.isArray(error.errors) &&
                <ul>
					    {error.errors.map(createErrors)}
                </ul>
                }
            </li>
        );
    }

    /**
     * Render all errors & sub-errors.
     *
     * @param error
     * @param i
     */
    function createFormErrors(error: ApiError, i: number): ReactNode {
        return (
            <li key={`error_${i}`}>
                {error.message}
                {isFormError(error) &&
                <>
					    {Object.keys(error.formErrors)?.length > 0 &&
					    <ul>
							    {Object.values(error.formErrors).map(createFormErrors)}
					    </ul>
                    }
                </>
                }
            </li>
        );
    }

    return (
        <div>
            <ReportErrorModal
                isOpen={showReportErrorModal}
                onClose={() => setShowReportErrorModal(false)}
                apiError={props.apiError}
            />
            <FrameOneAutoPanel
                isOpen={true}
                toggle={dismiss}
            >
                <FrameAutoPanelHeader
                    title="Error"
                    toggle={dismiss}
                />

                <FrameAutoPanelBody>
                    {isFormError(props.apiError)
                        ? <>
                            <b>{props.apiError?.message}</b>
                            {Object.keys(props.apiError?.formErrors)?.length > 0 &&
                            <ul>
                                {Object.values(props.apiError.formErrors).map(createFormErrors)}
                            </ul>
                            }
                        </>
                        : <>
                            <b>{props.apiError?.message}</b>
                            {props.apiError?.errors?.length > 0 &&
                            <ul>
                                {props.apiError.errors.map(createErrors)}
                            </ul>
                            }
                        </>
                    }
                </FrameAutoPanelBody>

                <FrameAutoPanelFooter>
                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={() => setShowReportErrorModal(true)}
                    >
                        Report
                    </FrameButton>

                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={dismiss}
                    >
                        Dismiss
                    </FrameButton>
                </FrameAutoPanelFooter>
            </FrameOneAutoPanel>
        </div>
    );
}

export default ErrorModal;
