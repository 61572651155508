/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressBook,
    AddressBookFromJSON,
    AddressBookFromJSONTyped,
    AddressBookToJSON,
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    DiscordInfo,
    DiscordInfoFromJSON,
    DiscordInfoFromJSONTyped,
    DiscordInfoToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
    UserAllOf,
    UserAllOfFromJSON,
    UserAllOfFromJSONTyped,
    UserAllOfToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

import {
     GodAdminFromJSONTyped,
     StandardAdminFromJSONTyped,
     MerchantFromJSONTyped,
     StandardUserFromJSONTyped,
     SupportAgentFromJSONTyped,
     PartnerFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    updatedAt: number;
    /**
     * 
     * @type {UserType}
     * @memberof User
     */
    type: UserType;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    disabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    skipped?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    notificationMenuOrderStatus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    notificationMarketing?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof User
     */
    phoneNumber?: PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    ordersEmail?: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof User
     */
    ordersPhoneNumber?: PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    ordersFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    ordersLastName?: string;
    /**
     * See moment.tz.names()
     * @type {string}
     * @memberof User
     */
    timeZone?: string;
    /**
     * 
     * @type {Array<AddressBook>}
     * @memberof User
     */
    addresses?: Array<AddressBook>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    favorites?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    lastSpin?: number;
    /**
     * url to load the gravatar associated with the user\'s email
     * @type {string}
     * @memberof User
     */
    gravatar?: string;
    /**
     * ID of the user\'s default payment method, if present
     * @type {string}
     * @memberof User
     */
    defaultPaymentMethodId?: string;
    /**
     * 
     * @type {Asset}
     * @memberof User
     */
    profilePicture?: Asset;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    experiencePoints?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    community?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    leftCommunityAt?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    joinedCommunityAt?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    previousCommunity?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    pointsEarnedForCommunity?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    monthlyPointsEarnedForCommunity?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    rankWithinCommunity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isEjectedFromCommunity?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    nftProfilePicture?: string;
    /**
     * 
     * @type {DiscordInfo}
     * @memberof User
     */
    discord?: DiscordInfo;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'GOD_ADMIN') {
            return GodAdminFromJSONTyped(json, true);
        }
        if (json['type'] === 'STANDARD_ADMIN') {
            return StandardAdminFromJSONTyped(json, true);
        }
        if (json['type'] === 'MERCHANT') {
            return MerchantFromJSONTyped(json, true);
        }
        if (json['type'] === 'STANDARD_USER') {
            return StandardUserFromJSONTyped(json, true);
        }
        if (json['type'] === 'SUPPORT_AGENT') {
            return SupportAgentFromJSONTyped(json, true);
        }
        if (json['type'] === 'PARTNER') {
            return PartnerFromJSONTyped(json, true);
        }
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': UserTypeFromJSON(json['type']),
        'disabled': json['disabled'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'skipped': !exists(json, 'skipped') ? undefined : json['skipped'],
        'notificationMenuOrderStatus': !exists(json, 'notificationMenuOrderStatus') ? undefined : json['notificationMenuOrderStatus'],
        'notificationMarketing': !exists(json, 'notificationMarketing') ? undefined : json['notificationMarketing'],
        'email': json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberFromJSON(json['phoneNumber']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'ordersEmail': !exists(json, 'ordersEmail') ? undefined : json['ordersEmail'],
        'ordersPhoneNumber': !exists(json, 'ordersPhoneNumber') ? undefined : PhoneNumberFromJSON(json['ordersPhoneNumber']),
        'ordersFirstName': !exists(json, 'ordersFirstName') ? undefined : json['ordersFirstName'],
        'ordersLastName': !exists(json, 'ordersLastName') ? undefined : json['ordersLastName'],
        'timeZone': !exists(json, 'timeZone') ? undefined : json['timeZone'],
        'addresses': !exists(json, 'addresses') ? undefined : ((json['addresses'] as Array<any>).map(AddressBookFromJSON)),
        'favorites': !exists(json, 'favorites') ? undefined : json['favorites'],
        'lastSpin': !exists(json, 'lastSpin') ? undefined : json['lastSpin'],
        'gravatar': !exists(json, 'gravatar') ? undefined : json['gravatar'],
        'defaultPaymentMethodId': !exists(json, 'defaultPaymentMethodId') ? undefined : json['defaultPaymentMethodId'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : AssetFromJSON(json['profilePicture']),
        'level': !exists(json, 'level') ? undefined : json['level'],
        'experiencePoints': !exists(json, 'experiencePoints') ? undefined : json['experiencePoints'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'community': !exists(json, 'community') ? undefined : json['community'],
        'leftCommunityAt': !exists(json, 'leftCommunityAt') ? undefined : json['leftCommunityAt'],
        'joinedCommunityAt': !exists(json, 'joinedCommunityAt') ? undefined : json['joinedCommunityAt'],
        'previousCommunity': !exists(json, 'previousCommunity') ? undefined : json['previousCommunity'],
        'pointsEarnedForCommunity': !exists(json, 'pointsEarnedForCommunity') ? undefined : json['pointsEarnedForCommunity'],
        'monthlyPointsEarnedForCommunity': !exists(json, 'monthlyPointsEarnedForCommunity') ? undefined : json['monthlyPointsEarnedForCommunity'],
        'rankWithinCommunity': !exists(json, 'rankWithinCommunity') ? undefined : json['rankWithinCommunity'],
        'isEjectedFromCommunity': !exists(json, 'isEjectedFromCommunity') ? undefined : json['isEjectedFromCommunity'],
        'nftProfilePicture': !exists(json, 'nftProfilePicture') ? undefined : json['nftProfilePicture'],
        'discord': !exists(json, 'discord') ? undefined : DiscordInfoFromJSON(json['discord']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': UserTypeToJSON(value.type),
        'disabled': value.disabled,
        'deleted': value.deleted,
        'skipped': value.skipped,
        'notificationMenuOrderStatus': value.notificationMenuOrderStatus,
        'notificationMarketing': value.notificationMarketing,
        'email': value.email,
        'phoneNumber': PhoneNumberToJSON(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'ordersEmail': value.ordersEmail,
        'ordersPhoneNumber': PhoneNumberToJSON(value.ordersPhoneNumber),
        'ordersFirstName': value.ordersFirstName,
        'ordersLastName': value.ordersLastName,
        'timeZone': value.timeZone,
        'addresses': value.addresses === undefined ? undefined : ((value.addresses as Array<any>).map(AddressBookToJSON)),
        'favorites': value.favorites,
        'lastSpin': value.lastSpin,
        'gravatar': value.gravatar,
        'defaultPaymentMethodId': value.defaultPaymentMethodId,
        'profilePicture': AssetToJSON(value.profilePicture),
        'level': value.level,
        'experiencePoints': value.experiencePoints,
        'nickname': value.nickname,
        'community': value.community,
        'leftCommunityAt': value.leftCommunityAt,
        'joinedCommunityAt': value.joinedCommunityAt,
        'previousCommunity': value.previousCommunity,
        'pointsEarnedForCommunity': value.pointsEarnedForCommunity,
        'monthlyPointsEarnedForCommunity': value.monthlyPointsEarnedForCommunity,
        'rankWithinCommunity': value.rankWithinCommunity,
        'isEjectedFromCommunity': value.isEjectedFromCommunity,
        'nftProfilePicture': value.nftProfilePicture,
        'discord': DiscordInfoToJSON(value.discord),
    };
}


