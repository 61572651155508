/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOverwolfGameBody
 */
export interface CreateOverwolfGameBody {
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameBody
     */
    gameId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOverwolfGameBody
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOverwolfGameBody
     */
    events: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOverwolfGameBody
     */
    isEventSupported: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOverwolfGameBody
     */
    isEventSupportReady: boolean;
}

export function CreateOverwolfGameBodyFromJSON(json: any): CreateOverwolfGameBody {
    return CreateOverwolfGameBodyFromJSONTyped(json, false);
}

export function CreateOverwolfGameBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOverwolfGameBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'events': json['events'],
        'isEventSupported': json['isEventSupported'],
        'isEventSupportReady': json['isEventSupportReady'],
    };
}

export function CreateOverwolfGameBodyToJSON(value?: CreateOverwolfGameBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameId': value.gameId,
        'name': value.name,
        'description': value.description,
        'events': value.events,
        'isEventSupported': value.isEventSupported,
        'isEventSupportReady': value.isEventSupportReady,
    };
}


