import { PiForkKnifeFill } from "react-icons/pi";
import FrameButton, { FrameOneButtonColor } from "../../components/buttons/FrameButton";
import { FaArrowRight } from "react-icons/fa";
import { RiVipDiamondLine } from "react-icons/ri";
import { HiOutlineTicket } from "react-icons/hi2";
import { HiSparkles } from "react-icons/hi";
import { MdEmojiPeople } from "react-icons/md";
import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import classNames from "classnames";

interface buttonType {
    color: FrameOneButtonColor;
    rightIcon: JSX.Element;
    text: string;
    to: string;
}

type AnimationDirection = "left" | "right" | "normal";

const steps = [
    {
        icon: <PiForkKnifeFill />,
        title: "ORDER DELIVERY OR PICK-UP",
        shrinkOnMobile: false,
        description: "Discover local restaurants and order your favorite meals",
        button: null,
    },
    {
        icon: <RiVipDiamondLine />,
        title: "PLAY AND CONNECT!",
        shrinkOnMobile: false,
        description: "Complete fun quests while you eat to earn points in GoVIP",
        button: {
            color: "white-outline",
            rightIcon: <FaArrowRight />,
            text: "Visit GoVIP",
            to: "/go-vip-dashboard",
        } as buttonType,
    },
    {
        icon: <img alt="fuel-logo" src={`${import.meta.env.VITE_CDN_URL}/images/FUEL-icon-white.png`} />,
        title: "DISCOVER FUEL TOKEN",
        shrinkOnMobile: true,
        description: "Utilize FUEL to unlock endless possibilities in the DevourGO",
        button: {
            color: "white-outline",
            rightIcon: <FaArrowRight />,
            text: "Load FUEL",
            to: "/load-fuel",
        } as buttonType,
    },
    {
        icon: <HiOutlineTicket />,
        title: "GET THE INDUSTRY PASS!",
        shrinkOnMobile: true,
        description: "The ultimate DevourGO membership with a variety of benefits",
        button: {
            color: "white-outline",
            rightIcon: <HiSparkles />,
            text: "Start Saving Now",
            to: "/the-industry-pass",
        } as buttonType,
    },
    /*
     * {
     *     icon: <MdEmojiPeople />,
     *     title: "JOIN YOUR GUILD",
     *     shrinkOnMobile: true,
     *     description: "Game community platform to compete and unlock rewards!",
     *     button: {
     *         color: "white-outline",
     *         rightIcon: <FaArrowRight />,
     *         text: "Visit Guilds",
     *         to: "/gofriends",
     *     } as buttonType,
     * },
     */
];

export function LandingPageBannerCarousel() {
    const [currentStep, setCurrentStep] = useState(1);
    const getImageUrl = (step: number) => {
        return `${import.meta.env.VITE_CDN_URL}/images/landing-bnr-${step + 1}-${isMobile ? "SM" : "LG"}.webp`;
    };
    const handleStepChange = useCallback(() => {
        setCurrentStep((prevStep) => prevStep === steps.length ? 1 : prevStep + 1);
    }, [currentStep]);

    const handleStepClick = (index: number) => {
        setCurrentStep(index + 1);
    };

    const handleStepScroll = () => {
        const container = document.querySelector(".landing-page-banner-carousel_scroller");
        const stepElement = document.getElementById(`landing-page-banner-carousel-step-${currentStep}`);

        if (container && stepElement) {
            const containerRect = container.getBoundingClientRect();
            const stepRect = stepElement.getBoundingClientRect();
            const offsetLeft = stepRect.left - containerRect.left;

            (container as HTMLElement).scrollTo({
                left: container.scrollLeft + offsetLeft,
                behavior: "smooth",
            });
        }
    };
    useEffect(() => {
        const interval = setInterval(handleStepChange, 4000);
        return () => clearInterval(interval);
    }, [handleStepChange]);

    useEffect(handleStepScroll, [currentStep]);

    return (
        <div className="landing-page-banner-carousel">
            <div className="landing-page-banner-carousel_scroller">
                {steps.map((stepData, index) =>
                    <div
                        key={stepData.title + index}
                        id={`landing-page-banner-carousel-step-${index + 1}`}
                        style={{
                            backgroundImage: `url(${getImageUrl(index)})`,
                        }}
                        className={"landing-page-banner-carousel_container"}
                    >
                        <div
                            className={classNames("landing-page-banner-carousel_container_content", {
                                "shrink-on-mobile": stepData.shrinkOnMobile,
                            })}
                        >
                            <div className="landing-page-banner-carousel_container_content_icon">{stepData.icon}</div>
                            <div className="landing-page-banner-carousel_container_content_text">
                                <h1>{stepData.title}</h1>
                                <p>{stepData.description}</p>
                            </div>

                            {stepData.button && <FrameButton to={stepData.button.to} color={stepData.button.color} size={"normal"}>
                                {stepData.button.text}
                                {stepData.button.rightIcon}
                            </FrameButton>}
                        </div>
                    </div>)}
            </div>

            <div className="landing-page-banner-carousel_steps">
                {steps.map((step, index) =>
                    <div
                        key={index}
                        onClick={() => handleStepClick(index)}
                        className={`landing-page-banner-carousel_steps_step ${
                            currentStep === index + 1 ? "active" : ""
                        }`}
                    ></div>)}
            </div>
        </div>
    );
}
