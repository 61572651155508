/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InlineResponse20016,
    InlineResponse20016FromJSON,
    InlineResponse20016FromJSONTyped,
    InlineResponse20016ToJSON,
    OWEventTypeResponse,
    OWEventTypeResponseFromJSON,
    OWEventTypeResponseFromJSONTyped,
    OWEventTypeResponseToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOverwolfEventTypesResponse
 */
export interface GetOverwolfEventTypesResponse {
    /**
     * 
     * @type {PaginationInfo}
     * @memberof GetOverwolfEventTypesResponse
     */
    paginationInfo: PaginationInfo;
    /**
     * 
     * @type {Array<OWEventTypeResponse>}
     * @memberof GetOverwolfEventTypesResponse
     */
    eventTypes: Array<OWEventTypeResponse>;
}

export function GetOverwolfEventTypesResponseFromJSON(json: any): GetOverwolfEventTypesResponse {
    return GetOverwolfEventTypesResponseFromJSONTyped(json, false);
}

export function GetOverwolfEventTypesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOverwolfEventTypesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paginationInfo': PaginationInfoFromJSON(json['paginationInfo']),
        'eventTypes': ((json['eventTypes'] as Array<any>).map(OWEventTypeResponseFromJSON)),
    };
}

export function GetOverwolfEventTypesResponseToJSON(value?: GetOverwolfEventTypesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paginationInfo': PaginationInfoToJSON(value.paginationInfo),
        'eventTypes': ((value.eventTypes as Array<any>).map(OWEventTypeResponseToJSON)),
    };
}


