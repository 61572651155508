import {BrandMap, LoginAccessPoint, OrderDiscount} from "@devour/client";
import classNames from "classnames";
import {formatDate} from "@/utils/formatDate";
import {isDesktop} from "react-device-detect";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {ButtonHTMLAttributes, ReactElement, useContext, useState} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {FaMinus, FaPlus} from "react-icons/fa6";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import AssetImage from "@/components/AssetImage";

interface Props {
    brandMap: BrandMap;
    promo: OrderDiscount;
    toggleEmailModal?: (showModal: boolean) => void;
}

export function BrandMapOtherPromoCard(props: Props) {
    const {brandMap, promo, toggleEmailModal} = props;

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);
    const {
        brandMapState,
        selectedPromo,
        setSelectedPromo,
        setBrandMapState,
    } = useContext(BrandMapContext);
    const promoSelectionStep = brandMapState === BrandMapStates.SELECT_PROMOTION;

    let availabilityText: string;
    let promoValid: boolean = true;

    if (promo.expiresAt) {
        availabilityText = `Expires on ${formatDate(promo.expiresAt)}`;
        promoValid = promo.expiresAt >= Date.now();
    } else if (promo.maxRedemptions) {
        const availableQuantity: number = promo.maxRedemptions - promo.timesRedeemed;
        availabilityText = availableQuantity ? `${availableQuantity} Available` : "Unavailable";
        promoValid = !!availableQuantity;
    } else {
        availabilityText = "Unlimited Availability";
    }

    function renderSelectPromoButton(): ReactElement {
        const isPromoSelected = promo.id === selectedPromo;

        if (promo.isBrandMapAutoApplied) {
            return <span className="brand-map-promotions_others_card_auto-applied-tag">Auto-applied</span>;
        }

        if (promoSelectionStep && promoValid) {
            return (
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="gray"
                    size="icon"
                    rightIcon={isPromoSelected ? FaMinus : FaPlus}
                    className={`brand-map-promotions_icon-btn ${isPromoSelected ? "deselect" : "select"}`}
                    onClick={() => setSelectedPromo(isPromoSelected ? "" : promo.id)}
                />
            );
        }
    }

    function onExpandDescription(e) {
        e?.stopPropagation();
        setDescriptionExpanded(!descriptionExpanded);
    }

    function renderDescription(): ReactElement {
        return (
            <>
                {!isDesktop && <div className="brand-map-promotions_others_card_content_see-more">
                    <span>See Details</span>
                    <button onClick={onExpandDescription}>
                        {descriptionExpanded ? <FaChevronUp/> : <FaChevronDown/>}
                    </button>
                </div>}

                <p className="brand-map-promotions_others_card_content_description">
                    {promo.description}
                </p>
            </>
        );
    }

    function handleSecondaryPromoOnClick(): void {
        if (promoSelectionStep) {
            return;
        }

        if (brandMap.loginAccessPoint === LoginAccessPoint.PAGE && !fullToken) {
            toggleEmailModal?.(true);
        } else {
            setBrandMapState(BrandMapStates.SELECT_PROMOTION);
        }
    }

    return (
        <div
            className={classNames(
                "brand-map-promotions_others_card",
                "brand-map-promotions_card", {
                    "is-selected": selectedPromo === promo.id || promo.isBrandMapAutoApplied,
                },
            )}
            onClick={handleSecondaryPromoOnClick}
        >
            {renderSelectPromoButton()}
            <div className="brand-map-promotions_others_card_img-container">
                {promo.image
                    ? <AssetImage
                        assetId={promo.image}
                        alt={promo.label}
                    />
                    : <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/landing-background.webp`}
                        alt={promo.label}
                    />
                }
            </div>
            <div
                className={classNames(
                    "brand-map-promotions_others_card_content",
                    {"is-expanded": descriptionExpanded},
                )}
                onMouseEnter={() => setDescriptionExpanded(true)}
                onMouseLeave={() => setDescriptionExpanded(false)}
            >
                <div className="brand-map-promotions_others_card_content_availability">{availabilityText}</div>
                <h5 className="brand-map-promotions_others_card_content_label">{promo.label}</h5>
                {!promoSelectionStep && renderDescription()}
            </div>
        </div>
    );
}