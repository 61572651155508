/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhoneNumberBody,
    PhoneNumberBodyFromJSON,
    PhoneNumberBodyFromJSONTyped,
    PhoneNumberBodyToJSON,
} from './';

/**
 * 
 * @export
 * @interface RegisterBody
 */
export interface RegisterBody {
    /**
     * 
     * @type {PhoneNumberBody}
     * @memberof RegisterBody
     */
    phoneNumber?: PhoneNumberBody;
    /**
     * 
     * @type {string}
     * @memberof RegisterBody
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBody
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterBody
     */
    anonymousSpinValue?: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterBody
     */
    referralCode?: string;
}

export function RegisterBodyFromJSON(json: any): RegisterBody {
    return RegisterBodyFromJSONTyped(json, false);
}

export function RegisterBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberBodyFromJSON(json['phoneNumber']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'anonymousSpinValue': !exists(json, 'anonymousSpinValue') ? undefined : json['anonymousSpinValue'],
        'referralCode': !exists(json, 'referralCode') ? undefined : json['referralCode'],
    };
}

export function RegisterBodyToJSON(value?: RegisterBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phoneNumber': PhoneNumberBodyToJSON(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'anonymousSpinValue': value.anonymousSpinValue,
        'referralCode': value.referralCode,
    };
}


