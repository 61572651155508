import FrameButton from "@/components/buttons/FrameButton";
import { useAsset } from "@/hooks/useAsset";
import { useGetUnauthenticatedNftGroupings } from "@/hooks/useGetUnauthenticatedNftGroupings";
import { OrderDiscount, NftOwnershipInformation, OrderDiscountCalculation, OrderDiscountType } from "@devour/client";
import classNames from "classnames";
import { FC } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router";

interface props {
    promo: OrderDiscount;
    onOrderNow?: () => void;
    nftOwnershipResponse: NftOwnershipInformation;
    className?: string;
}

export const RestaurantPromoCard: FC<props> = ({ promo, className, onOrderNow }) => {
    const { data: allNftGroupings } = useGetUnauthenticatedNftGroupings(false);
    const {placeId} = useParams<{ placeId: string; }>();
    const { data: promoImage } = useAsset(promo?.image);
    const navigate = useNavigate();
    const promoGroupingIdsSet = new Set(promo.nftGroupings);
    const isGlobalPromo = promo.nftGroupings?.length === 0 && promo.businesses?.length === 0;
    const isFreeDelivery = promo.calculation == OrderDiscountCalculation.PERCENTAGE && promo.amount == 100 && promo.type == OrderDiscountType.DELIVERY;
    const nftGroupings = allNftGroupings?.nftGroupings.filter((grouping) => promoGroupingIdsSet.has(grouping.id) || !promo.nftGroupings || promoGroupingIdsSet.size === 0);
    const hasOrderNowCTA = promo?.businesses?.length > 0 && !isGlobalPromo;

    const getImageUrl = () => {
        if (isFreeDelivery) {
            return `${import.meta.env.VITE_CDN_URL}/images/0delivery-${isMobile ? "sm" : "lg"}.webp`;
        }
        return promoImage?.url || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`;
    };

    const handleOrderNow = () => {
        if (promo.businesses?.length == 1) {
            navigate(`/restaurants/${promo.businesses[0]}${placeId ? `/${placeId}` : ""}`);
        } else
            if (onOrderNow) {
                onOrderNow();
            }

    };


    const getNftPlusCount = () => {
        if (nftGroupings?.length > 1) {
            if (nftGroupings.length - 5 > 0) {
                return `+${nftGroupings.length - 5}`;
            }
            return "";
        }
        return nftGroupings[0]?.name;

    };

    if (!promo || !nftGroupings) {
        return null;
    }

    return (
        <div className={classNames("restaurant-promo-card", {
            "is-global": isGlobalPromo,
        })}>
            <div className="restaurant-promo-card_content">
                {!hasOrderNowCTA && <div className="restaurant-promo-card_content_nfts">
                    <div className="restaurant-promo-card_content_nfts_imgs">
                        {nftGroupings?.slice(0, 5)?.map((grouping, index) =>
                            <img
                                style={{zIndex: 5 - index }}
                                key={grouping.id}
                                src={
                                    grouping.hero?.url ||
                                        `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`
                                }
                                alt={grouping.name}
                            />)}
                    </div>
                    <span>
                        {getNftPlusCount()}
                    </span>
                </div>}
                <p className="restaurant-promo-card_content_description">{promo.description || promo.label}</p>
                {hasOrderNowCTA && <FrameButton size="pill" onClick={handleOrderNow} color="purple" className="restaurant-promo-card_content_order-now">Order now</FrameButton>}
            </div>

            <div className="restaurant-promo-card_image">
                <img
                    src={getImageUrl()}
                    alt={promo.label}
                />
            </div>
        </div>
    );
};
