import {ButtonHTMLAttributes, ChangeEventHandler, FormEvent, ReactElement, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {
    CreateUserBody,
    OnboardingApi,
    PhoneNumberBody,
    ReferralLinkTextBody,
    ReferralsApi,
    UsersApi,
} from "@devour/client";
import {
    addError,
    decrementLoading,
    incrementLoading,
    updateCurrentUser,
} from "../redux/meta/metaActions";
import getConfig, {getMagicConfig} from "../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import AuthHeaderLogo from "../components/auth/AuthHeaderLogo";
import FrameButton from "../components/buttons/FrameButton";
import FrameOnePhoneNumberInput from "../components/inputs/FrameOnePhoneNumberInput";
import {login} from "../redux/auth/authActions";
import IconInput from "../components/inputs/IconInput";
import {AiFillCheckCircle} from "react-icons/ai";
import {BsChevronDown} from "react-icons/bs";
import {getRedirectRoute} from "../utils/redirectRouteHandler";
import {magic} from "../utils/magic";
import {removeReferralCode} from "@/redux/affiliateReferrals/affiliateActions";

export const defaultCreateUserBody: CreateUserBody = {
    password: "",
    confirmPassword: "",
    email: "",
    phoneNumber: {
        countryCode: "US",
        nationalNumber: "",

    },
    firstName: "",
    lastName: "",
    referralCode: "",
};

function SignUp(): ReactElement {

    // keep order
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const redirect = searchParams.get("redirect");
    const signedUrlKey = searchParams.get("suk");
    const magicCredential = searchParams.get("magic_credential");
    const navigate = useNavigate();
    const reduxReferralCode = useSelector((store: IStore) => store.affiliateStore.referralCode);
    const reduxReferralExpiry = useSelector((store: IStore) => store.affiliateStore.referralExpiry);
    const referralCode = (reduxReferralCode && Date.now() < reduxReferralExpiry) ? reduxReferralCode : "";

    const [
        createUserBody,
        setCreateUserBody,
    ] = useState<CreateUserBody>({
        ...defaultCreateUserBody,
        referralCode: referralCode,
    });
    const [
        referralCodeValid,
        setReferralCodeValid,
    ] = useState<boolean | undefined>(undefined);
    const [
        showReferralSection,
        setShowReferralSection,
    ] = useState<boolean>(false);
    const [
        showReferralIconRotated,
        setShowReferralIconRotated,
    ] = useState<boolean | undefined>(undefined);
    const [
        referralLinkText,
        setReferralLinkText,
    ] = useState<ReferralLinkTextBody | undefined>(undefined);

    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const lastRestaurantId = useSelector((store: IStore) => store.metaStore.lastRestaurantId);
    const lastMenuOrderId = useSelector((store: IStore) => store.metaStore.lastMenuOrderId);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const lastBrandMap = useSelector((store: IStore) => store.metaStore.lastBrandMap);
    const addressBooks = currentUser?.user?.addresses;

    useEffect(() => {

        // if the user doesn't have a magic credential in their search param, redirect them
        if (!magicCredential) {
            navigate("/restaurants-search");
        }

        /**
         * Get the current referral link text.
         *
         */
        async function getReferralLinkText() {
            try {

                const formData = await new ReferralsApi(getConfig()).getReferralLinkText({});

                setReferralLinkText({
                    header: formData.header,
                    description: formData.description,
                    relationshipType: formData.relationshipType,
                });

            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }

        // only render if there was a referral code stored
        if (referralCode) {
            void getReferralLinkText();
        }

    }, [referralCode]);

    useEffect(() => {

        /**
         * Get the current referral link text.
         *
         */
        async function getOnboardingData() {
            dispatch(incrementLoading());
            try {

                const formData = await new OnboardingApi(getConfig()).getOnboardingInformation({
                    signedUrlKey: signedUrlKey,
                });

                setCreateUserBody({
                    ...createUserBody,
                    email: formData.email || createUserBody.email,
                    firstName: formData.firstName || createUserBody.firstName,
                    lastName: formData.lastName || createUserBody.lastName,
                    phoneNumber: formData.phoneNumber || createUserBody.phoneNumber,
                });

            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }

        // only render if there was a referral code stored
        if (signedUrlKey) {
            void getOnboardingData();
        }

    }, [signedUrlKey]);

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof Omit<CreateUserBody, "phoneNumber">): ChangeEventHandler<HTMLInputElement> {
        return (e) => {
            setCreateUserBody({
                ...createUserBody,
                [key]: e.target.value,
            });
        };
    }

    /**
     * Handle the phone number input onChange.
     *
     * @param phoneNumber
     */
    function phoneNumberOnChange(phoneNumber: PhoneNumberBody): void {
        setCreateUserBody({
            ...createUserBody,
            phoneNumber,
        });
    }

    /**
     * Handle the referral code input onChange.
     *
     * @param referralCode
     */
    function referralCodeOnChange(referralCode: string): void {
        const newUserBody = {...createUserBody,
            referralCode: referralCode.length
                ? referralCode
                : ""};
        setCreateUserBody(newUserBody);
    }

    /**
     * Handle the referral code validation states.
     *
     * @param newReferralCode
     */
    function handleReferralCodeInputValidation(newReferralCode: string): void {

        referralCodeOnChange(newReferralCode);

        if (newReferralCode.length === 0) {
            setReferralCodeValid(undefined); // reset color on 0

        } else if (newReferralCode.length < 22 || (/[^a-zA-Z0-9]/).test(newReferralCode)) {
            setReferralCodeValid(false); // too short or invalid chars

        } else {
            setReferralCodeValid(true);
        }
    }

    /**
     * Filter out unfilled fields before submission
     * @param skip
     */
    function prepareRegisterBody(skip: boolean) {
        if (skip || createUserBody === defaultCreateUserBody) {
            return {};
        }
        const body = {};
        Object.keys(createUserBody).forEach((index) => {
            if (index === "phoneNumber") {
                if (createUserBody[index].nationalNumber !== "") {
                    body["phoneNumber"] = createUserBody[index];
                }

            } else if (createUserBody[index] !== "") {
                body[index] = createUserBody[index];
            }
        });
        return body;
    }

    function submitSignUp(skip: boolean): (e?: FormEvent<HTMLFormElement>) => Promise<void> {
        return async (e?) => {
            e?.preventDefault();
            dispatch(incrementLoading());

            try {

                // call the login api with the magic_credential, if the API returns 200 with a token, then log the user in as normal
                const res = await new UsersApi(getMagicConfig(magicCredential)).register({
                    registerBody: prepareRegisterBody(skip),
                });

                if (signedUrlKey) {
                    await new OnboardingApi(getConfig(res.token)).mergeUserWithSignedUrlKey({
                        signedUrlKey: signedUrlKey,
                    });
                }

                // grab the profile to save into redux
                const userRes = await new UsersApi(getConfig(res.token)).getProfile();

                // login to magic locally
                if (await magic.user.isLoggedIn() === false) {
                    await magic.auth.loginWithCredential(magicCredential);
                }

                //  save the token and profile into redux
                dispatch(login(res.token));
                dispatch(updateCurrentUser(userRes));

                // remove referral code from store
                dispatch(removeReferralCode());

                // handle external referral submission -- needs to be done after user data is updated

                navigate(await getRedirectRoute(redirect, lastSearchedPlaceId, lastRestaurantId, lastMenuOrderId, addressBooks, lastBrandMap.slug), {replace: true});
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        };
    }

    return (
        <div className="sign-up">
            <div className="sign-up_spacer-top">
                <AuthHeaderLogo/>
            </div>

            <div className="sign-up_content">
                <h3 className="sign-up_content_title">Sign up</h3>

                {
                    referralCode && referralLinkText &&
                    <div className="sign-up_content_referral-link-container">
					    <div className="sign-up_content_referral-link-container_header">
					        <AiFillCheckCircle className="sign-up_content_referral-link-container_header_icon"/>
					        <p className="sign-up_content_referral-link-container_text">
					            {referralLinkText.header}
					        </p>
					    </div>
					    {
					        referralLinkText.description && referralLinkText.description !== "" &&
					        <p className="sign-up_content_referral-link-container_text">
							    {referralLinkText.description}
					        </p>
					    }
                    </div>
                }

                <form onSubmit={submitSignUp(false)}>
                    <div className="sign-up_content_name-row">
                        <div>
                            <label>First Name</label>
                            <input
                                placeholder="Enter first name"
                                value={createUserBody.firstName}
                                onChange={inputOnChange("firstName")}
                            />
                        </div>

                        <div>
                            <label>Last Name</label>
                            <input
                                placeholder="Enter last name"
                                value={createUserBody.lastName}
                                onChange={inputOnChange("lastName")}
                            />
                        </div>
                    </div>

                    <div className="sign-up_content_phone-container">
                        <FrameOnePhoneNumberInput
                            value={createUserBody.phoneNumber}
                            onChange={phoneNumberOnChange}
                        />
                    </div>

                    {
                        !referralCode &&
                        <div className="sign-up_content_referral-code-container">
						    <div
						        className="sign-up_content_referral-code-container_referral-code-label-container"
						        onClick={() => {
						            setShowReferralSection(!showReferralSection);
						            if (showReferralIconRotated === undefined) {
						                setShowReferralIconRotated(true);
						            } else {
						                setShowReferralIconRotated(!showReferralIconRotated);
						            }
						        }}
						    >
						        <label>Referral Code</label>
						        <BsChevronDown
						            className={showReferralIconRotated === undefined
						                ? "sign-up_content_referral-code-container_referral-code-label-container_icon-default"
						                : showReferralIconRotated === false
                                            ? "sign-up_content_referral-code-container_referral-code-label-container_icon-up"
						                    : "sign-up_content_referral-code-container_referral-code-label-container_icon-down"
						            }
						        />
						    </div>
						    <div
						        className={showReferralSection
						            ? "sign-up_content_referral-code-container_referral-code-content-visible"
						            : "sign-up_content_referral-code-container_referral-code-content-hidden"}
						    >
						        <IconInput
						            className={referralCodeValid
						                ? "sign-up_content_referral-code-container_input-success"
						                : referralCodeValid === false
                                            ? "sign-up_content_referral-code-container_input-fail"
						                    : ""} // default input styling
						            icon={referralCodeValid
						                ? AiFillCheckCircle
						                : undefined} // only render check if code is valid
						            placeholder={"Enter referral code"}
						            maxLength={22}
						            value={createUserBody.referralCode
						                ? createUserBody.referralCode
						                : ""}
						            onChange={(e) => {
						                handleReferralCodeInputValidation(e.target.value);
						            }}
						        />
						        {
						            referralCodeValid === false &&
						            <p className="sign-up_content_referral-code-container_referral-code-invalid-text">
										Error: Invalid code
						            </p>
						        }
						        <p className="sign-up_content_referral-code-container_referral-code-text">
									Did a friend share a referral code? Enter it here.
						        </p>
						    </div>
                        </div>
                    }

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="normal"
                        className="sign-up_content_sign-up-button"
                        forwardProps={{
                            type: "submit",
                            disabled: referralCodeValid === false,
                        }}
                    >
						Sign Up
                    </FrameButton>

                    <br/>

                    <div
                        className="sign-up_content_sign-up-container"
                    >
                        <div
                            className="sign-up_content_sign-up-container_link"
                            onClick={() => submitSignUp(true)()}
                        >
                            <p className="sign-up_content_sign-up-container_link_text">
								Skip
                            </p>
                        </div>
                    </div>

                </form>

                <div className="sign-up_content_legal">
                    <hr/>
                    <p className="sign-up_content_legal_p">
						By clicking “Sign up” above, you acknowledge that you have read and understood, and agree to
						DevourGO’s{" "}
                        <Link to="/terms-of-use">Terms of Use</Link>,{" "}
                        <Link to="/privacy-policy">Privacy Policy</Link>, and{" "}
                        <Link to="/cookie-policy">Cookie Policy</Link>
                    </p>
                </div>
            </div>

            <div className="sign-up_spacer-bottom"/>
        </div>
    );
}

export default SignUp;
