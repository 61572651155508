/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWGame,
    OWGameFromJSON,
    OWGameFromJSONTyped,
    OWGameToJSON,
    OWGameResponseAllOf,
    OWGameResponseAllOfFromJSON,
    OWGameResponseAllOfFromJSONTyped,
    OWGameResponseAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface OWGameResponse
 */
export interface OWGameResponse extends OWGame {
    /**
     * 
     * @type {boolean}
     * @memberof OWGameResponse
     */
    hasUserStats?: boolean;
}

export function OWGameResponseFromJSON(json: any): OWGameResponse {
    return OWGameResponseFromJSONTyped(json, false);
}

export function OWGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWGameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...OWGameFromJSONTyped(json, ignoreDiscriminator),
        'hasUserStats': !exists(json, 'hasUserStats') ? undefined : json['hasUserStats'],
    };
}

export function OWGameResponseToJSON(value?: OWGameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...OWGameToJSON(value),
        'hasUserStats': value.hasUserStats,
    };
}


